<template>
  <div
    v-if="statistics"
    class="d-flex flex-column flex-md-row mt-4"
  >
    <statistics-field
      icon="mdi-account-group-outline"
      title="Kontakte"
      :statistic="companyStatisticsContacts"
    />

    <v-divider
      vertical
      class="mx-4 d-none d-md-block"
    />

    <statistics-field
      icon="mdi-account-plus-outline"
      title="Neue Kontakte"
      :statistic="companyStatisticsNewContacts"
    />

    <v-divider
      vertical
      class="mx-4 d-none d-md-block"
    />

    <statistics-field
      icon="mdi-sack-percent"
      title="Profit"
      :statistic="companyStatisticsProfit"
    />
  </div>
</template>

<script>
import STATISTICS from './queries/Statistics.gql'
import StatisticsField from './StatisticsField.vue'
import currencyMixin from '@/mixins/currency'

export default {
  components: { StatisticsField },
  mixins: [currencyMixin],
  props: {
    companyId: {
      type: String,
      default: ''
    }
  },
  apollo: {
    statistics: {
      query: STATISTICS,
      variables () {
        return {
          companyId: this.companyId
        }
      },
      skip () {
        return !this.companyId
      }
    }
  },
  computed: {
    companyStatisticsContacts () {
      return this.statistics?.totalLeadCount?.total?.toString() || '0'
    },
    companyStatisticsNewContacts () {
      return this.statistics?.totalLeadCountByStatus?.new.toString() || '0'
    },
    companyStatisticsProfit () {
      const min = 100000
      const max = 1500000
      const profit = Math.random() * (max - min) + min
      return this.formatPrice(profit).toString()
    }
  }

}
</script>

<style>

</style>
