<template>
  <div>
    <BaseHeader
      title="Spracheinstellungen"
      :style-variant="3"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>Passen Sie die Sprache an um die App optimal nutzen zu können.</p>
      </template>
    </BaseHeader>
    <v-card
      outlined
      style="overflow:hidden"
    >
      <v-row>
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          sm="4"
        >
          <div class="design-wrapper">
            <Leaf
              class="design-elements"
              width="1000"
              style="top:-1200px; right: 100px; opacity: 0.2"
              fill
            />

            <Leaf
              class="design-elements rotate"
              width="80"
              style="top:0px; right: 150px; opacity: 0.8"
              stroke
              :stroke-width="1"
            />

            <Leaf
              class="design-elements rotate"
              width="80"
              style="top:0px; right:130px; opacity: 0.3"
              fill
            />
            <SingleCircle
              class="design-elements"
              width="10px"
              style="top:75px; right: 160px; opacity: 0.8"
              fill
            />
            <SingleCircle
              class="design-elements"
              width="10px"
              style="top:75px; right: 180px; opacity: 0.8"
              fill
            />
            <SingleCircle
              class="design-elements"
              width="10px"
              style="top:75px; right: 200px; opacity: 0.8"
              fill
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="select-wrapper"
        >
          <div
            class="d-flex align-center"
          >
            <v-select
              v-model="language"
              :items="items"
              label="Sprachauswahl"
              outlined
              dense
              hide-details
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from './HeaderDesign.vue'
import Leaf from '@/components/design-elements/leaf.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'

export default {
  components: { BaseHeader, Leaf, SingleCircle, HeaderDesign },

  data () {
    return {
      items: ['DE', 'EN', 'FR'],
      language: 'DE'
    }
  }
}
</script>

<style scoped>
.design-wrapper {
  position: relative;
  width:300px;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.select-wrapper{
  padding-top: 60px;
  padding-bottom: 60px;
}
.rotate{
  transform: rotate(90deg);
}
</style>
