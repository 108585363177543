<template>
  <defs>
    <linearGradient
      id="svg-gradient"
      x1="0%"
      y1="0%"
      x2="0%"
      y2="100%"
    >
      <stop
        offset="0%"
        :style="`stop-color:${strokeStop}`"
      />
      <stop
        offset="100%"
        :style="`stop-color:${strokeStart}`"
      />
    </linearGradient>
  </defs>
</template>

<script>
import svg from '@/mixins/svg'
export default {
  mixins: [svg]
}
</script>
