<template>
  <div
    id="legend"
    class="mt-3 ml-3 rounded-lg legend pa-2"
  >
    <div
      v-for="(marker,index) in availableMarkerTypes"
      :key="marker"
      class="d-flex"
      :class="{'pb-2': index < availableMarkerTypes.length -1}"
    >
      <v-checkbox
        v-model="activeFilterType"
        hide-details
        class="ma-0 pa-0 align-self-center"
        :value="marker"
      />

      <v-img
        :src="markerTypes[marker]?.icon"
        max-width="30px"
      />

      <div class="align-self-center body-2">
        {{ markerTypes[marker]?.title }}
      </div>
    </div>
  </div>
</template>

<script>
const markerTypes = {
  active: {
    icon: '/img/marker/active.svg',
    title: 'Bewertung'
  },
  inactive: {
    icon: '/img/marker/inactive.svg',
    title: 'In Bearbeitung'
  },
  user: {
    icon: '/img/marker/user.svg',
    title: 'Selbst generiert'
  },
  propertyRequest: {
    icon: '/img/marker/propertyRequest.svg',
    title: 'Wunschtraum'
  }
}

export default {
  props: {
    map: {
      type: Object,
      default: () => {}
    },
    markers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      markerTypes,
      activeFilterType: [...Object.keys(markerTypes)]
    }
  },
  computed: {
    availableMarkerTypes () {
      const keys = []
      Object.keys(this.markerTypes).forEach(key => {
        if (this.markers.some(marker => marker.type === key)) keys.push(key)
      })
      return keys
    }
  },
  watch: {
    activeFilterType () {
      this.$emit('apply-filter', this.activeFilterType)
    }

  },
  mounted () {
    const legend = document.getElementById('legend')
    const legendDiv = document.createElement('div')
    legendDiv.appendChild(legend)
    // eslint-disable-next-line no-undef
    this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legendDiv)
  }
}
</script>

<style scoped>
.legend {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
</style>
