import { appBranding } from '@/lib/features'

export default {
  computed: {
    platformAssetUrl () {
      return (appBranding?.isActive && appBranding?.config?.assetUrl)
    },
    platform () {
      return (appBranding?.isActive && appBranding?.config?.platform)
    },
    legalCompanyName () {
      return 'MultiScale AG'
    },
    readablePlatformName () {
      return 'MultiScale'
    },
    dataPrivacyUrl () {
      return (appBranding?.isActive && appBranding?.config?.dataPrivacyUrl)
    },
    publicDataPrivacyUrl () {
      return (appBranding?.isActive && appBranding?.config?.publicDataPrivacyUrl)
    },
    publicTOCandLicenceUrl () {
      return (appBranding?.isActive && appBranding?.config?.publicTOCandLicenceUrl)
    },
    imprintUrl () {
      return (appBranding?.isActive && appBranding?.config?.imprintUrl)
    },
    primaryColor () {
      return '#0081A7'
    },
    gradientColors () {
      return {
        start: '#0081A7',
        end: '#00a9db'
      }
    },
    staticGradientColors () {
      return {
        start: '#049dc9',
        end: '#00a9db99'
      }
    }
  }
}
