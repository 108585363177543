import branding from './branding'
import { shadeColor } from '@/lib/color'

export default {
  mixins: [branding],
  props: {
    fill: {
      type: Boolean,
      default: false
    },
    stroke: {
      type: Boolean,
      default: false
    },
    strokeWidth: {
      type: Number,
      default: 2
    },
    fillColorStart: {
      type: String,
      default: ''
    },
    fillColorStop: {
      type: String,
      default: ''
    },
    strokeColorStart: {
      type: String,
      default: ''
    },
    strokeColorStop: {
      type: String,
      default: ''
    }
  },
  computed: {
    fillStart () {
      return this.fillColorStart || this.gradientColors.start || this.$vuetify.theme.themes.light.primary
    },
    fillStop () {
      return this.fillColorStop || this.gradientColors.end || shadeColor(this.$vuetify.theme.themes.light.primary, 80)
    },
    strokeStart () {
      return this.strokeColorStart || this.gradientColors.start || this.$vuetify.theme.themes.light.primary
    },
    strokeStop () {
      return this.strokeColorStop || this.gradientColors.end || shadeColor(this.$vuetify.theme.themes.light.primary, 80)
    }
  }
}
