import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import { WebAuth } from 'auth0-js'
import * as Sentry from '@sentry/vue'
import bus, { eventNames } from '@/lib/eventBus'
import { signup } from '@/lib/features'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

const isRedirectedAfterAuthentication = () => (
  window.location.search.includes('code=') &&
  window.location.search.includes('state=')
)

let instance

export const getInstance = () => instance

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  domain,
  clientId,
  audience,
  databaseConnection
}) => {
  if (instance) return instance

  instance = new Vue({
    data () {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        webAuth: null,
        error: null
      }
    },
    async created () {
      this.auth0Client = await createAuth0Client({
        domain,
        audience,
        client_id: clientId,
        redirect_uri: redirectUri,
        useRefreshTokens: true
      })

      this.webAuth = new WebAuth({
        domain,
        audience,
        clientID: clientId,
        redirectUri,
        responseType: 'code'
      })

      try {
        if (isRedirectedAfterAuthentication()) {
          await this.auth0Client.getTokenSilently()
          onRedirectCallback(getLocationBeforeLogin())
        }
      } catch (err) {
        this.error = err
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = prepareUser(await this.auth0Client.getUser())
        this.loading = false

        if (this.isAuthenticated) {
          bus.$emit(eventNames.USER_LOGGED_IN, this.user.companyId)
        }

        Sentry.configureScope(scope => {
          if (this.user) {
            scope.setTag('companyId', this.user.companyId)
            scope.setUser({ id: this.user.sub.split('|')?.[1] }) // sub: 'auth0|some-id'
          } else {
            scope.clear()
          }
        })
      }
    },
    methods: {
      login (username, password) {
        return new Promise((resolve, reject) => {
          this.webAuth.login({
            username,
            password,
            realm: databaseConnection
          }, err => {
            if (err) {
              reject(err)
            } else {
              resolve()
            }
          })
        })
      },
      logout (options) {
        localStorage.clear()
        return this.auth0Client.logout(options)
      },
      signup () {
        if (!signup?.isActive) {
          throw new Error('Signup not active!')
        }
        return this.auth0Client.loginWithRedirect({
          screen_hint: 'signup'
        })
      },
      changePassword ({ email }) {
        return new Promise((resolve, reject) => {
          this.webAuth.changePassword({
            connection: databaseConnection,
            email
          }, function (err, res) {
            if (err) {
              reject(err)
            } else {
              resolve(res)
            }
          })
        })
      },
      getIdTokenClaims (options) {
        return this.auth0Client.getIdTokenClaims(options)
      },
      async getTokenSilently (options) {
        const token = await this.auth0Client.getTokenSilently(options)
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = prepareUser(await this.auth0Client.getUser())
        return token
      },
      rememberLocationBeforeLogin
    }
  })

  function rememberLocationBeforeLogin () {
    sessionStorage.setItem('locationBeforeLogin', window.location.pathname)
  }

  function getLocationBeforeLogin () {
    const location = sessionStorage.getItem('locationBeforeLogin')
    sessionStorage.removeItem('locationBeforeLogin')
    return location
  }

  const prepareUser = user => {
    if (user) {
      return {
        ...user,
        ...user[process.env.VUE_APP_AUTH0_META_CLAIM] || {}
      }
    }
    return user
  }

  return instance
}

export const Auth0Plugin = {
  install (Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
