import Vue from 'vue'
import { getConsentCookie, setConsentCookie } from '@/lib/cookieHandling'

const useCookieConsent = () => new Vue({
  data: () => ({
    choice: null
  }),
  created () {
    try {
      const choice = getConsentCookie()
      if (Array.isArray(choice)) {
        this.choice = choice
      }
    } catch (err) {
      // Someone obviously manipulated entry
    }
  },
  methods: {
    accept (choice) {
      if (Array.isArray(choice)) {
        this.choice = choice
        setConsentCookie(choice)
      }
    },
    hasAccepted () {
      return Boolean(this.choice)
    }
  }
})

export default {
  install (Vue) {
    Vue.prototype.$cookieConsent = useCookieConsent()
  }
}
