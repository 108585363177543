<template>
  <div class="mt-4">
    <v-card
      outlined
      class="pt-6 root"
    >
      <h2 class="pl-4 mb-3 title">
        Wann ist meine Expertise gefragt?
      </h2>

      <template v-if="!this.$apollo.queries.rawData.loading">
        <apex-chart
          v-if="enoughData"
          height="80%"
          :options="chartOptions"
          :series="series"
        />
        <div
          v-else
          class="not-enough-data title font-weight-regular grey--text"
        >
          Sobald genügend Kontakte gesammelt wurden,<br>
          zeigen wir hier, zu welchen Uhrzeiten und Wochentagen die Kontakte aktiv waren.
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
import LEAD_INTERACTIVITY from './LeadInteractivity.gql'

const ApexChart = () => import(/* webpackChunkName: "vue-apexcharts" */'vue-apexcharts')

const MIN_DATAPOINTS = 1

export default {
  components: {
    ApexChart
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'heatmap',
          parentHeightOffset: 0,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        colors: [this.$vuetify.theme.themes.light.primary],
        dataLabels: {
          enabled: false
        },
        xaxis: {
          title: {
            text: 'Uhrzeit'
          }
        },
        yaxis: {
          reversed: true
        }
      }
    }
  },
  computed: {
    enoughData () {
      const total = this.rawData?.reduce((weekTotal, week) => {
        return weekTotal + week.hours.reduce((dayTotal, hour) => dayTotal + hour, 0)
      }, 0)
      return total > MIN_DATAPOINTS
    },
    series () {
      return ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']
        .map((name, index) => {
          const week = this.rawData?.find(date => date.weekday === index + 1)
          const hours = week?.hours ?? []
          return {
            name,
            data: hours.map((count, hour) => ({
              x: `${hour}`, // if it's a string xaxis.type will be category
              y: count
            }))
          }
        })
    }
  },
  apollo: {
    rawData: {
      query: LEAD_INTERACTIVITY,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>

<style scoped>
.root {
  height: 400px;
}

.not-enough-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
</style>
