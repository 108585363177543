import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import vuetify from './plugins/vuetify'
import CookieConsentPlugin from './modules/cookieConsent/plugin'
import { Auth0Plugin } from './auth'
import { FeaturePlugin } from 'feature-config'
import { createProvider } from './vue-apollo'
import * as Sentry from '@sentry/vue'
import './mixins/global'
import './registerServiceWorker'
import '@/lib/animation'
import VueGtm from '@gtm-support/vue2-gtm'
import bus, { eventNames } from '@/lib/eventBus'
import { getConsentCookie } from '@/lib/cookieHandling'
import { getSignUpGuard } from '@/lib/signUpGuard'
import { getLicenseAgreementGuard } from '@/lib/licenseAgreementGuard'
import { getAvvGuard } from '@/lib/avvGuard'
import { hasCompanyGuard } from './lib/companyGuard'

Vue.use(CookieConsentPlugin)

const apolloProvider = createProvider()
const router = createRouter(apolloProvider)

Vue.use(FeaturePlugin, {
  onCreated () {
    bus.$on(eventNames.USER_LOGGED_IN, (companyId) => {
      if (companyId) {
        this.loadAllFeaturesForCompany(companyId, { apolloProvider, interval: 900000 })
      }
    })
  }
})

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  databaseConnection: process.env.VUE_APP_AUTH0_DATABASE_CONNECTION,
  redirectUri: window.location.origin + '/auth/callback',
  onRedirectCallback: targetPath => {
    router.push({
      ...(targetPath
        ? { path: targetPath }
        : { name: 'dashboard' }
      ),
      replace: true
    })
  }
})

if (process.env.VUE_APP_STAGE && (process.env.VUE_APP_STAGE === 'staging' || process.env.VUE_APP_STAGE === 'production')) {
  const choice = getConsentCookie()
  let enabled = false
  if (choice && choice.indexOf('tracking') > -1) {
    enabled = true
  }
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    enabled // TODO react to cookie change
  })
}

Sentry.init({
  Vue,
  attachProps: true,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_STAGE,
  enabled: process.env.VUE_APP_STAGE && process.env.VUE_APP_STAGE !== 'local'
})

Vue.config.productionTip = false

router.beforeEach(hasCompanyGuard())
router.beforeEach(getSignUpGuard(apolloProvider))
router.beforeEach(getLicenseAgreementGuard(apolloProvider))
router.beforeEach(getAvvGuard(apolloProvider))

new Vue({
  router,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
