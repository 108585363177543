<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <StatisticsCard
          title="Neue Kontakte"
          :statistic="statistics && String(statistics.totalLeadCountByStatus?.new)"

          icon="mdi-account-plus-outline"
          icon-color="white"
          category="Neue Kontakte"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <StatisticsCard
          title="Alle Kontakte"
          :statistic="statistics && String(statistics?.totalLeadCount.total)"

          icon="mdi-account-group-outline"
          icon-color="white"
          category="Kontakte"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <StatisticsCard
          title="Verkaufsvolumen"
          :statistic="statistics && formatPrice('12312')"
          icon="mdi-home-analytics"
          icon-color="white"
          category="Immobilienvolumen"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <StatisticsCard
          title="Provisionsumsatz"
          :statistic="statistics && formatPrice('1231') "
          icon="mdi-sack-percent"
          icon-color="white"
          category="Provisionsumsatz"
        />
      </v-col>
    </v-row>

    <v-card
      outlined
      class="pa-5 mt-4 no-focus"
      @click="showInteractionDetails = !showInteractionDetails"
    >
      <div class="d-flex align-center">
        <div>
          <v-icon
            class="static-gradient-background rounded-circle pa-2"
            color="white"
            x-large
          >
            mdi-gesture-double-tap
          </v-icon>
        </div>

        <div
          class="pb-0 headline font-weight-medium d-flex w-full"
        >
          <div class="d-flex flex-column px-4">
            <span class="title grey--text font-weight-light">Interaktionen</span>
            <div class="pb-0 font-weight-medium">
              <v-progress-circular
                v-if="!statistics"
                indeterminate
              />
              <span v-else>{{ directTrafficCount + facebookTrafficCount + googleTrafficCount + qrCodeTrafficCount + customTrafficCount }}</span>
            </div>
          </div>

          <v-spacer />

          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="d-flex align-center"
          >
            <InteractionStatistics
              :direct-traffic-count="directTrafficCount"
              :qr-code-traffic-count="qrCodeTrafficCount"
              :facebook-traffic-count="facebookTrafficCount"
              :google-traffic-count="googleTrafficCount"
            />
          </div>

          <v-spacer />
          <v-btn
            v-if="customTrafficCount>0"
            icon
            small
          >
            <v-icon>
              {{ showInteractionDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
        </div>
      </div>

      <div
        v-if="$vuetify.breakpoint.smAndDown"
        class="d-flex mt-6"
      >
        <InteractionStatistics
          :direct-traffic-count="directTrafficCount"
          :qr-code-traffic-count="qrCodeTrafficCount"
          :facebook-traffic-count="facebookTrafficCount"
          :google-traffic-count="googleTrafficCount"
        />
      </div>

      <v-expand-transition v-if="customTrafficCount>0">
        <div
          v-show="showInteractionDetails"
          class="mt-5"
        >
          <v-divider />

          <div class="mt-4 title grey--text font-weight-light">
            Individuellere Aufteilung
          </div>
          <span class="text-body-2 grey--text font-weight-light">Sie haben eigene Quellen (utm-source) definiert?</span>

          <v-card-text class="px-0 pb-0 mx-0 mb-0 mt-2">
            <div class="d-flex">
              <v-row>
                <v-col
                  v-for="(count, name) of customTraffic"
                  :key="name"
                  sm="3"
                  class="py-0 text-center grey--text text-sm-left"
                >
                  <span
                    class="mr-1 subtitle-1 font-weight-bold grey--text text--darken-3"
                  >{{ count }}</span> <br class="d-block d-sm-none"> {{ name }}
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import STATISTICS from './Statistics.gql'
import StatisticsCard from './StatisticsCard.vue'
import InteractionStatistics from './InteractionStatistics.vue'
import currencyMixin from '@/mixins/currency'

export default {
  components: {
    StatisticsCard,
    InteractionStatistics
  },
  mixins: [currencyMixin],
  data () {
    return {
      showInteractionDetails: false
    }
  },
  apollo: {
    statistics: {
      query: STATISTICS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  },
  computed: {
    customTraffic () {
      if (!this.statistics) return
      const dict = {}
      this.statistics.totalTrafficSource.forEach(item => {
        if (item.type && !['google', 'facebook', 'qrCode', 'mailfunnel'].includes(item.type)) {
          dict[item.type] = (dict[item.type] || 0) + item.count
        }
      })
      return dict
    },
    directTrafficCount () {
      if (!this.statistics) return
      return this.statistics.totalTrafficSource.reduce((sum, item) => {
        if (!item.type) {
          return sum + item.count
        }
        return sum
      }, 0)
    },
    customTrafficCount () {
      if (!this.statistics) return
      return this.statistics.totalTrafficSource.reduce((sum, item) => {
        if (item.type && !['google', 'facebook', 'qrCode'].includes(item.type)) {
          return sum + item.count
        }
        return sum
      }, 0)
    },
    facebookTrafficCount () {
      if (!this.statistics) return
      const facebook = this.statistics.totalTrafficSource.find(item => item.type === 'facebook')
      if (facebook) {
        return facebook.count
      }
      return 0
    },
    googleTrafficCount () {
      if (!this.statistics) return
      const google = this.statistics.totalTrafficSource.find(item => item.type === 'google')
      if (google) {
        return google.count
      }
      return 0
    },
    qrCodeTrafficCount () {
      if (!this.statistics) return
      const qrCode = this.statistics.totalTrafficSource.find(item => item.type === 'qrCode')
      if (qrCode) {
        return qrCode.count
      }
      return 0
    }
  }
}
</script>
<style scoped>
.no-focus:focus:before{
  background: none;
}
</style>
