import { getInstance as getAuthInstance } from '../auth/index'
import { authGuard } from '../guards/auth'

export function hasCompanyGuard () {
  return async (to, _, next) => {
    authGuard(async () => {
      const auth = getAuthInstance()

      if (!auth.isAuthenticated || ['company-creation', 'logout'].includes(to.name)) {
        return next()
      }
      if (to.name === 'company-creation' && auth.user.companyId) {
        return next({ name: 'dashboard', replace: true })
      }

      if (!auth.user.companyId) {
        return next({ name: 'company-creation', replace: true })
      }

      next()
    })
  }
}
