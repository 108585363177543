const productTypes = Object.freeze({
  BASIC: 'BASIC',
  EXPERT: 'EXPERT',
  SHOWCASE: 'SHOWCASE',
  LIGHT: 'LIGHT'
})

const isExpertUser = ({ productType }) => productType === productTypes.EXPERT || productType === productTypes.SHOWCASE
const isBasicUser = ({ productType }) => productType === productTypes.BASIC
const isLightUser = ({ productType }) => productType === productTypes.LIGHT
const isAuthenticatedAndExpertUser = ({ isAuthenticated, user }) => isAuthenticated && isExpertUser(user)
const isAuthenticatedAndBasicUser = ({ isAuthenticated, user }) => isAuthenticated && isBasicUser(user)
const isAuthenticatedAndLightUser = ({ isAuthenticated, user }) => isAuthenticated && user.productType === productTypes.LIGHT

module.exports = { isExpertUser, isBasicUser, isAuthenticatedAndExpertUser, isAuthenticatedAndBasicUser, isLightUser, isAuthenticatedAndLightUser }
