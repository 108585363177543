import bus, { eventNames } from '@/lib/eventBus'

const MAX_ANIMATION_COUNT_PER_DAY = 3

bus.$on(eventNames.TRIGGER_ANIMATION, () => {
  const currentDate = new Date()
  const storedDate = localStorage.getItem('animation.date')
  const latestAnimationDate = new Date(storedDate)
  const animationCount = parseInt(localStorage.getItem('animation.count'))

  // if new day, reset count
  if (!latestAnimationDate || currentDate.getDate() !== latestAnimationDate.getDate()) {
    localStorage.setItem('animation.date', currentDate)
    localStorage.setItem('animation.count', 1)
    bus.$emit(eventNames.SHOW_ANIMATION)
  } else {
    if (!animationCount) {
      localStorage.setItem('animation.count', 1)
      bus.$emit(eventNames.SHOW_ANIMATION)
    } else if (animationCount < MAX_ANIMATION_COUNT_PER_DAY) {
      localStorage.setItem('animation.count', animationCount + 1)
      bus.$emit(eventNames.SHOW_ANIMATION)
    }
  }
})
