import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  theme: {
    themes: {
      light: {
        primary: '#0081A7',
        secondary: '#FF9E00',
        success: '#4CAF50',
        red: '#FA0143',
        orange: '#FE9930',
        error: '#FA0143',
        info: '#4895EF',
        warning: '#FE9930',
        leadActivity: '#07BEB8',
        systemActivity: '#4895EF',
        userActivity: '#C77DFF',
        skyLight: '#A2D2FF',
        dogerBlue: '#4895EF'
      }
    }
  }
})
