<template>
  <div>
    <v-expand-transition>
      <v-alert
        v-if="error"
        type="error"
        dense
        tile
        text
      >
        {{ getErrorMessage(error) }}
      </v-alert>
    </v-expand-transition>

    <div>
      <v-form @submit="login">
        <v-text-field
          v-model.trim="email"
          label="E-Mail-Adresse"
          type="email"
          outlined
          hide-details
          class="mb-4"
          prepend-inner-icon="mdi-email-outline"
        />

        <v-text-field
          v-model="password"
          label="Passwort"
          type="password"
          hide-details
          outlined
          prepend-inner-icon="mdi-lock-outline"
        />

        <div class="justify-end d-flex mt-2 mb-4">
          <router-link
            :to="{ name: 'password-reset-request' }"
            class="font-weight-bold no-border"
          >
            Passwort vergessen?
          </router-link>
        </div>
        <div
          class="px-0 d-flex flex-column"
        >
          <loading-button
            color="primary"
            type="submit"
            class="mb-5"
            style="height:50px"
            :loading="submitting"
          >
            anmelden
          </loading-button>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import LoadingButton from '@/components/loading-button'
import { supportContact } from '@/lib/features'

export default {
  components: {
    LoadingButton
  },
  data: () => ({
    email: '',
    password: '',
    error: null,
    submitting: false
  }),

  computed: {
    errorMessages () {
      return {
        emptyFields: 'Bitte geben Sie Ihre E-Mail-Adresse und Ihr Passwort an.',
        accessDenied: 'Passwort oder Benutzername falsch. Bitte überprüfen Sie Ihre Eingaben.',
        tooManyAttempts: 'Ihr Zugang wurde wegen zu vieler Fehlversuche gesperrt. Wir haben Ihnen eine E-Mail mit Anweisungen geschickt, wie Sie Ihren Zugang wieder entsperren können.',
        blockedUser: `Es ist ein Fehler mit Ihrem Account aufgetreten. Bitte kontaktieren Sie ${supportContact.config.email}`,
        unknown: 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut.'
      }
    }
  },
  methods: {
    async login (evt) {
      evt.preventDefault()

      this.error = null

      const formValid = this.email && this.password

      if (formValid) {
        this.submitting = true

        try {
          await this.$auth.login(this.email, this.password)
        } catch (err) {
          if (err.code === 'access_denied') {
            this.error = 'accessDenied'
          } else if (err.code === 'too_many_attempts') {
            this.error = 'tooManyAttempts'
          } else if (err.code === 'blocked_user') {
            this.error = 'blockedUser'
          } else {
            this.error = 'unknown'
          }
          Sentry.addBreadcrumb({
            category: 'auth',
            message: 'Login failed'
          })
          Sentry.captureException(err)
        }

        this.submitting = false
      } else {
        this.error = 'emptyFields'
      }
    },
    getErrorMessage (code) {
      return this.errorMessages[code]
    }
  }
}
</script>
<style scoped>
.no-border{
  text-decoration: none;
}
</style>
