
const IFRAME_ITEM_VALUE = {
  overview: 'overview',
  similar: 'similar',
  demographics: 'socio-economics/demographics',
  economics: 'socio-economics/economics',
  housing: 'socio-economics/housing',
  market: 'market',
  noise: 'environment/noise',
  view: 'environment/view',
  nuisance: 'environment/nuisance',
  amenities: 'amenities',
  accessibility: 'accessibility',
  project: 'project'
}

export const IFRAME_VIEW_ITEMS = {
  de: [
    {
      label: 'Überblick',
      value: IFRAME_ITEM_VALUE.overview
    },
    {
      label: 'Vergleichsobjekte',
      value: IFRAME_ITEM_VALUE.similar
    },
    {
      label: 'Demografie',
      value: IFRAME_ITEM_VALUE.demographics
    },
    {
      label: 'Wirtschaft',
      value: IFRAME_ITEM_VALUE.economics
    },
    {
      label: 'Immobilien',
      value: IFRAME_ITEM_VALUE.housing
    },
    {
      label: 'Geräusch',
      value: IFRAME_ITEM_VALUE.noise
    },
    {
      label: 'Aussicht',
      value: IFRAME_ITEM_VALUE.view
    },
    {
      label: 'Immissionen',
      value: IFRAME_ITEM_VALUE.nuisance
    },
    {
      label: 'Nahversorgung',
      value: IFRAME_ITEM_VALUE.amenities
    },
    {
      label: 'Erreichbarkeit',
      value: IFRAME_ITEM_VALUE.accessibility
    },
    {
      label: 'Bauvorhaben',
      value: IFRAME_ITEM_VALUE.project
    }
  ],
  ch: [
    {
      label: 'Überblick',
      value: IFRAME_ITEM_VALUE.overview
    },
    {
      label: 'Vergleichsobjekte',
      value: IFRAME_ITEM_VALUE.similar
    },
    {
      label: 'Demografie',
      value: IFRAME_ITEM_VALUE.demographics
    },
    {
      label: 'Wirtschaft',
      value: IFRAME_ITEM_VALUE.economics
    },
    {
      label: 'Markt',
      value: IFRAME_ITEM_VALUE.market
    },
    {
      label: 'Geräusch',
      value: IFRAME_ITEM_VALUE.noise
    },
    {
      label: 'Aussicht',
      value: IFRAME_ITEM_VALUE.view
    },
    {
      label: 'Immissionen',
      value: IFRAME_ITEM_VALUE.nuisance
    },
    {
      label: 'Nahversorgung',
      value: IFRAME_ITEM_VALUE.amenities
    },
    {
      label: 'Erreichbarkeit',
      value: IFRAME_ITEM_VALUE.accessibility
    },
    {
      label: 'Bauvorhaben',
      value: IFRAME_ITEM_VALUE.project
    }
  ],
  at: [
    {
      label: 'Überblick',
      value: IFRAME_ITEM_VALUE.overview
    },
    {
      label: 'Vergleichsobjekte',
      value: IFRAME_ITEM_VALUE.similar
    },
    {
      label: 'Demografie',
      value: IFRAME_ITEM_VALUE.demographics
    },
    {
      label: 'Wirtschaft',
      value: IFRAME_ITEM_VALUE.economics
    },
    {
      label: 'Immobilien',
      value: IFRAME_ITEM_VALUE.housing
    },
    {
      label: 'Nahversorgung',
      value: IFRAME_ITEM_VALUE.amenities
    },
    {
      label: 'Erreichbarkeit',
      value: IFRAME_ITEM_VALUE.accessibility
    },
    {
      label: 'Bauvorhaben',
      value: IFRAME_ITEM_VALUE.project
    }
  ]
}
