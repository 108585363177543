<template>
  <v-snackbar
    v-model="isVisible"
    right
    :color="color"
  >
    <div class="d-flex justify-space-between">
      <span class="d-flex align-center">{{ text }}</span>
      <v-btn
        icon
        small
        color="white"
        @click="isVisible = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'

export default {
  data () {
    return {
      isVisible: false,
      text: '',
      color: '',
      hideTimeout: null
    }
  },
  mounted () {
    bus.$on(eventNames.SHOW_SNACKBAR, (data) => {
      this.showSnackbar(data.color, data.text, data.time)
    })
  },
  methods: {
    showSnackbar (newColor, newText, newTime) {
      this.color = newColor
      this.text = newText
      this.isVisible = true
      this.hideTimeout = setTimeout(() => (this.isVisible = false), newTime || 5000)
    }
  },
  unmounted () {
    clearTimeout(this.hideTimeout)
  }

}
</script>
