export default {
  methods: {
    formatPrice (price, currency, fractionDigits = 0) {
      const { locale = 'de-DE' } = this.$auth.user
      const currencies = { 'de-DE': 'EUR', 'de-AT': 'EUR', 'de-CH': 'CHF' }

      return new Intl.NumberFormat(locale, {
        currency: currency || currencies[locale],
        style: 'currency',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
      }).format(price)
    }
  }
}
