import todoStates from '@/modules/todo/enums/ToDoStates'
import bus, { eventNames } from '@/lib/eventBus'
import GET_TODOS from '@/modules/todo/queries/GetTodos.gql'
import START_TODO from '@/modules/todo/queries/StartTodo.gql'
import COMPLETE_TODO from '@/modules/todo/queries/CompleteTodo.gql'

const READBALE_STATES = {
  PENDING: 'Wartet auf Start',
  OPEN: 'Offen',
  STARTED: 'Begonnen',
  FINISHED: 'Erledigt'
}

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: ''
    },
    externalFlowId: {
      type: String,
      default: ''
    },
    endConditionManual: {
      type: Boolean,
      default: false
    },
    startConditionManual: {
      type: Boolean,
      default: false
    },
    endConditionEvent: {
      type: String,
      default: ''
    },
    startConditionEvent: {
      type: String,
      default: ''
    }
  },
  computed: {
    isDone () {
      return this.state === todoStates.FINISHED
    },
    isOpen () {
      return this.state === todoStates.OPEN
    },
    isStarted () {
      return this.state === todoStates.STARTED
    },
    hasAppcuesFlow () {
      return Boolean(this.externalFlowId)
    },
    checkIcon () {
      return this.isDone ? 'mdi-check-decagram' : 'mdi-minus-circle-outline'
    },
    getButtonText () {
      if (this.state === todoStates.OPEN) {
        return this.buttonText
      } else if (this.state === todoStates.FINISHED) {
        return 'Neu starten'
      }
      return READBALE_STATES[this.state]
    }
  },
  methods: {
    async triggerAppcuesFlow () {
      if (window.Appcues) {
        window.Appcues.show(this.externalFlowId)
      }
    },
    async startTodo () {
      try {
        await this.$apollo.mutate({
          mutation: START_TODO,
          variables: {
            input: {
              todoTemplateSlug: this.slug
            }
          },
          refetchQueries: [
            { query: GET_TODOS, variables: { input: { companyId: this.$auth.user.companyId } } }
          ]
        })

        if (this.hasAppcuesFlow) {
          await this.triggerAppcuesFlow()
        }

        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Aufgabe erfolgreich gestartet.' })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Aufgabe konnte nicht gestartet werden.' })
      }
    },
    async completeTodo (endConditionEvent) {
      try {
        await this.$apollo.mutate({
          mutation: COMPLETE_TODO,
          variables: {
            input: {
              todoTemplateSlug: this.slug,
              ...endConditionEvent && {
                event: endConditionEvent
              }
            }
          },
          refetchQueries: [
            { query: GET_TODOS, variables: { input: { companyId: this.$auth.user.companyId } } }
          ]
        })

        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Aufgabe erfolgreich abgeschlossen.' })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Aufgabe konnte nicht abgeschlossen werden.' })
      }
    }
  },
  mounted () {
    // listen for appcues events to complete a todo
    // @see https://docs.appcues.com/en_US/dev-api-data/javascript-api-developer
    if (window.Appcues) {
      window.Appcues.on('flow_completed', async (event) => {
        if (event.flowId === this.externalFlowId) {
          await this.completeTodo(this.endConditionEvent)
        }
      })
    }

    // listen for our internal end condition events to complete a todo
    bus.$on(this.endConditionEvent, async ({ slug }) => {
      if (slug === this.slug) {
        await this.completeTodo(this.endConditionEvent)
      }
    })
  }
}
