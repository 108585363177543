<template>
  <div>
    <Leaf
      class="banner-design-elements"
      width="100"
      style="right: 200px; top:-70px; opacity: 0.8"
      stroke
      :stroke-width="1"
    />
    <Leaf
      class="banner-design-elements"
      width="100"
      style="right: 140px; top:-90px; opacity: 0.5"
      fill
    />
    <Leaf
      class="banner-design-elements"
      width="300"
      style="right: -20px; top:-90px; opacity: 0.2"
      fill
    />
  </div>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'

export default {
  components: {
    Leaf
  }
}
</script>
