<template>
  <div class="h-full">
    <v-row
      align="center"
      justify="center"
      class="h-full pa-0 ma-0"
    >
      <v-col
        cols="6"
        class="justify-center px-5 ma-0 d-none d-md-flex align-center login-hero"
      >
        <GradientBackground
          :top-left="gradientColors.start"
          :top-right="gradientColors.end"
          :bottom-left="gradientColors.start"
          :bottom-right="gradientColors.end"
        />
        <Tilt
          :options="{max:10}"
          class="overlay"
        >
          <div class="hero-wrapper">
            <Blob
              :width="heroWidth+'px'"
              fill-color-start="#ffffff"
              fill-color-stop="#ededed"
              fill
            />

            <Leaf
              class="hero-design-element"
              :width="parseInt(heroWidth/10)+'px'"
              :style="getDefaultStyle(30,0,1)"
              stroke
              :stroke-width="1"
            />

            <Leaf
              class="hero-design-element"
              :width="parseInt(heroWidth/20)+'px'"
              :style="getDefaultStyle(30,0,1)"
              fill
            />

            <Leaf
              class="hero-design-element"
              :width="parseInt(heroWidth/5)+'px'"
              :style="getDefaultStyle(-200,-150,0.6)"
              fill
            />

            <SingleCircle
              class="hero-design-element"
              :width="parseInt(heroWidth/10)+'px'"
              :style="getDefaultStyle(-100,50,1)"
              stroke
            />

            <SingleCircle
              class="hero-design-element"
              :width="parseInt(heroWidth/7)+'px'"
              :style="getDefaultStyle(-30,60,0.8)"
              fill
            />
          </div>
        </Tilt>

        <v-img
          alt="Company Logo"
          class="login-logo"
          :src="require('@/../public/img/logo-light.png')"
          width="140"
          contain
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="justify-center h-full login-container ma-0 pa-0 d-flex"
      >
        <v-col
          cols="12"
          sm="8"
          class="h-full inner-container d-flex justify-space-between flex-column"
        >
          <div class="flex-1">
            <v-img
              alt="Company Logo"
              class="mt-2 ml-auto mr-auto d-block d-md-none"
              :src="require('@/../public/img/logo.png')"
              width="140"
              contain
            />
          </div>

          <slot />

          <div class="justify-center flex-1 mb-2 d-flex">
            <a
              :href="publicDataPrivacyUrl"
              target="_blank"
              rel="noopener noreferrer"
            >Datenschutz</a>
            <a
              :href="imprintUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="ml-4"
            >Impressum</a>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Tilt from '@/components/Tilt.vue'
import brandingMixin from '@/mixins/branding'

import Leaf from '@/components/design-elements/leaf.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'
import Blob from '@/components/design-elements/blob.vue'
import GradientBackground from '@/components/GradientBackground.vue'

export default {
  components: {
    Tilt,
    Leaf,
    SingleCircle,
    Blob,
    GradientBackground
  },
  mixins: [brandingMixin],
  data () {
    return {
      heroWidth: 500
    }
  },
  methods: {
    getDefaultStyle (top, right, opacity) {
      return { top: this.relativePos(top) + 'px', right: this.relativePos(right) + 'px', opacity }
    },
    relativeHeroElementWidth (x) {
      return parseInt(this.heroWidth / x)
    },
    relativePos (x) {
      return parseInt(this.heroWidth / 2) + x
    }
  }
}
</script>

<style scoped>
.content {
  width: 450px;
}
.login-container{
  background-color: white;
}

.inner-container{
  max-width: 500px;
}

.login-hero{
  position: relative;
  height: 100%;
}

.login-hero-image{
  object-fit: cover;
  width: 500px;
}

.login-logo{
  position: absolute;
  top:0;
  left:0;
  margin-top:30px;
  margin-left:30px;
  z-index: 2;
}

.hero-wrapper{
  position: relative;
}

.hero-design-element{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.overlay{
  z-index: 2;
  position: relative;
}

</style>
