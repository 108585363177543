import { getInstance } from 'feature-config'
import { getInstance as getAuthInstance } from '../auth/index'
import { authGuard } from '../guards/auth'
import AVV from '@/queries/Avv.gql'
import featureNames from '@/lib/featureNames'

/**
 * Checks if current user is responsible for the avv signing.
 * If so and an (updated) avv exists and it forces the user to accept it before using the app.
 * Routes to the avv page, if new avv exists or first time login of responsible user.
 *
 * @param {string} apolloProvider apollo instance
 * @returns {void}
 * @throws {Error}
 */
export function getAvvGuard (apolloProvider) {
  return async (to, from, next) => {
    authGuard(async () => {
      const auth = getAuthInstance()

      if (!auth.isAuthenticated) return next()

      if (['licenseAgreement', 'privacy', 'avv', 'legal', 'company-creation', 'logout'].includes(to.name)) return next()

      const featureConfig = getInstance()
      await featureConfig.loadAllFeaturesForCompany(auth.user.companyId, { apolloProvider, reload: false })

      const avvFeature = featureConfig.featuresBySlug[featureNames.AVV]
      const licenseAgreementFeature = featureConfig.featuresBySlug[featureNames.LICENSE_AGREEMENT]

      if (licenseAgreementFeature?.isActive && licenseAgreementFeature?.config?.hasAvv) {
        return next()
      }

      if (avvFeature?.isActive) {
        const apolloClient = apolloProvider.defaultClient

        const { data: { company } } = await apolloClient.query({
          query: AVV,
          variables: {
            id: auth.user.companyId
          }
        })

        if (!company) {
          throw new Error('Company not found:')
        }

        if ((company.avv?.acceptedByUserId && !company.avv?.acceptedAt)) {
          return next()
        }
        const updatedAvv = new Date(company.avv?.acceptedAt).getTime() < new Date(avvFeature.config.lastModifiedAt).getTime()
        if (!company.avv?.acceptedAt || updatedAvv) {
          return next({ name: 'avv', replace: true })
        }
      }

      next()
    })
  }
}
