<template>
  <v-card outlined>
    <v-card-title>
      Passwort zurücksetzen
    </v-card-title>

    <v-card-text>
      <p>
        In wenigen Minuten sollten Sie eine E-Mail von uns bekommen.
        Diese haben wir an <strong class="text-no-wrap">{{ email }}</strong> gesendet.
      </p>
      <p>
        Wenn Sie keine E-Mail bekommen, überprüfen Sie bitte Ihre
        E-Mail-Adresse sowie Ihr SPAM-Postfach.
      </p>
      <router-link :to="{ name: 'login' }">
        Zum Login
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    email: {
      type: String,
      default: ''
    }
  }
}
</script>
