<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="auto"
        class="mt-8 content"
      >
        <confirmation
          v-if="submittedEmail"
          :email="submittedEmail"
        />
        <e-mail-form
          v-else
          @submitted="handleSubmitted"
        />
        <v-card
          outlined
          class="mt-2"
        >
          <v-card-title>
            Anleitung
          </v-card-title>
          <v-card-text>
            <iframe
              width="100%"
              src="https://www.youtube.com/embed/N6w_cJpLBps"
              title="YouTube video player"
              frameborder="0"
              allowfullscreen
              style="aspect-ratio: 854/480;border-radius: 5px"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EMailForm from './EMailForm'
import Confirmation from './Confirmation'

export default {
  components: {
    EMailForm,
    Confirmation
  },
  data: () => ({
    submittedEmail: null
  }),
  methods: {
    handleSubmitted ({ email }) {
      this.submittedEmail = email
    }
  }
}
</script>

<style scoped>
.content {
  width: 450px;
}
</style>
