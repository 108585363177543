<template>
  <v-container class="layout-container">
    <user-data />
  </v-container>
</template>

<script>

import UserData from '@/modules/profile/UserData.vue'

export default {
  components: { UserData }

}
</script>
