import { getInstance } from 'feature-config'
import { getInstance as getAuthInstance } from '../auth/index'
import { authGuard } from '../guards/auth'
import LICENSE_AGREEMENT from '@/queries/LicenseAgreement.gql'
import featureNames from '@/lib/featureNames'

/**
 * Checks if current user is responsible for the license agreement signing.
 * If so and a (updated) license agreement exists and it forces the user to accept it before using the app.
 * Routes to the license agreement page, if new license agreement exists or first time login of responsible user.
 *
 * @param {string} apolloProvider apollo instance
 * @returns {void}
 * @throws {Error}
 */
export function getLicenseAgreementGuard (apolloProvider) {
  return async (to, from, next) => {
    authGuard(async () => {
      const auth = getAuthInstance()

      if (!auth.isAuthenticated) return next()

      // Legal pages that have to be visible even if no license agreement has been accepted
      if (['privacy', 'avv', 'legal', 'company-creation', 'logout', 'registration'].includes(to.name)) return next()

      const featureConfig = getInstance()
      await featureConfig.loadAllFeaturesForCompany(auth.user.companyId, { apolloProvider, reload: false })
      const licenseAgreementFeature = featureConfig.featuresBySlug[featureNames.LICENSE_AGREEMENT]

      if (licenseAgreementFeature?.isActive) {
        if (to.name === 'licenseAgreement') return next()
        const apolloClient = apolloProvider.defaultClient

        const { data: { company } } = await apolloClient.query({
          query: LICENSE_AGREEMENT,
          variables: {
            id: auth.user.companyId
          }
        })

        if (!company) {
          throw new Error('Company not found:')
        }
        const updatedLicense = new Date(company.licenseAgreement?.acceptedAt).getTime() < new Date(licenseAgreementFeature.config.lastModifiedAt).getTime()
        if (!company.licenseAgreement?.acceptedAt || updatedLicense) {
          return next({ name: 'licenseAgreement', replace: true })
        }
      } else {
        if (to.name === 'licenseAgreement') {
          return next({ name: 'dashboard', replace: true })
        }
      }
      next()
    })
  }
}
