<template>
  <v-container
    fluid
    class="justify-center d-flex align-center h-full"
  >
    <v-card
      outlined
      class="verification-card pa-5"
    >
      <v-card-title class="justify-center">
        E-Mail Verifizierung
      </v-card-title>

      <div class="justify-center d-flex align-center">
        <div v-if="state==='pending'">
          <p>
            <v-progress-circular
              :size="20"
              :width="2"
              indeterminate
              color="primary"
              class="mr-4"
              small
            />
            E-Mail wird verifiziert.
          </p>
        </div>

        <div v-if="state==='verified'">
          <div class="justify-center mb-4 d-flex">
            <v-icon
              color="primary"
              :size="30"
            >
              mdi-check-circle-outline
            </v-icon>
          </div>
          <p class="text-center">
            E-Mail erfolgreich verifiziert.
            Sie werden jetzt ausgeloggt. Bitte melden Sie sich anschließend neu an, um den Prozess abzuschließen.
          </p>
        </div>
        <div v-if="state==='failed'">
          <div class="justify-center mb-4 d-flex">
            <v-icon
              color="error"
              :size="30"
            >
              mdi-alert-circle-outline
            </v-icon>
          </div>
          <p
            class="text-center"
            v-html="error"
          />
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import featureNames from '@/lib/featureNames'
import VERIFY_EMAIL_CHANGE_REQUEST from '../queries/VerifyEmailChangeRequest.gql'

export default {
  components: { },

  data () {
    return {
      state: 'pending',
      error: '',
      timeoutDisplay: null,
      timeoutLogout: null
    }
  },

  async mounted () {
    // wait a bit to display the spinner
    this.timeoutDisplay = setTimeout(async () => {
      const { data: { verifyEmailChangeRequest } } = await this.verifyEmailById(this.$route.params.id)
      if (verifyEmailChangeRequest) {
        this.state = 'verified'
        this.timeoutLogout = setTimeout(() => { this.logout() }, 5000)
      }
    }, 2000)
  },

  unmounted () {
    clearTimeout(this.timeoutDisplay)
    clearTimeout(this.timeoutLogout)
  },

  methods: {
    logout () {
      this.$auth.logout()
    },
    async verifyEmailById (id) {
      try {
        return await this.$apollo.mutate({
          mutation: VERIFY_EMAIL_CHANGE_REQUEST,
          variables: {
            id
          }

        })
      } catch (error) {
        this.error = `Die Bestätigung ist fehlgeschlagen. Sie können weiterhin Ihre bisherige E-Mailadresse verwenden oder sich an unseren <a href="mailto:${
          this.$features.feature(featureNames.SUPPORT_CONTACT).config.support.email}">Support</a> wenden.`
        this.state = 'failed'
      }
    }
  }

}
</script>

<style scoped>
.verification-card{
  width: 400px;
}
</style>
