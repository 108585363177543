<template>
  <v-container class="pb-8 layout-container">
    <BaseHeader
      :title="baseHeaderTitle"
      :style-variant="1"
    >
      <template
        v-if="!hasScores"
        v-slot:design
      >
        <HeaderDesign />
      </template>
      <template
        v-if="hasScores"
        v-slot:button
      >
        <v-btn
          icon
          small
          class="toggle-btn"
          @click="isDetailsOpen = !isDetailsOpen"
        >
          <v-icon>
            {{ isDetailsOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </template>

      <template
        v-if="hasScores"
        v-slot:charts
      >
        <div class="chart-wrapper">
          <ScoreChart
            :score="scores.overall"
            label="Ihre Gesamtperformance"
          />
        </div>
      </template>

      <template v-slot:desc>
        <p v-if="isCorporate">
          Alles an einem Ort. Hier verwalten Sie alle Büros die mit Ihrer Organisation verknüpft sind.
        </p>
        <p v-else>
          Alles an einem Ort: Kontaktverwaltung, Kampagnen, Werbemittel, Landingpages und mehr.
        </p>
      </template>

      <template
        v-if="hasScores"
        v-slot:content
      >
        <ScoreDetails
          :scores="scores"
          :is-details-open="isDetailsOpen"
        />
      </template>
    </BaseHeader>

    <CorporateDashboard v-if="isCorporate" />

    <div v-if="!isCorporate">
      <email-verification
        v-if="showEmailVerification"
        :default-sender="defaultSender"
      />

      <Feature :feature-slug="featureNames.TODO">
        <Todo />
      </Feature>

      <statistics
        v-if="(!showEmailVerification || isSenderVerified) && !isLightUser"
      />

      <Feature :feature-slug="featureNames.ACQUISITION_BOOSTER">
        <AcquisitionBoosterDashboard
          v-if="invitationStatisticsForCompany?.total > 0"
          :invitation-statistics-for-company="invitationStatisticsForCompany"
        />
      </Feature>
      <v-row>
        <v-col
          cols="12"
          :md="isExpert ? 6 : 12"
        >
          <lead-map
            v-if="leads"
            :leads="leads"
          />
          <v-card
            v-else
            outlined
            class="mt-4 map-loader"
            style="height:400px"
          >
            <lead-map
              v-if="leads"
              :leads="leads"
            />
            <v-card
              v-else
              outlined
              class="mt-4 map-loader"
              style="height:400px"
            >
              <v-skeleton-loader
                height="400px"
                type="image"
              />
            </v-card>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <lead-interactivity v-if="isExpert" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import HeaderDesign from '@/modules/dashboard/HeaderDesign.vue'
import CorporateDashboard from '@/modules/corporateCompany/corporateDashboard'
import LeadMap from '@/modules/map'
import Statistics from '@/modules/statistics'
import Todo from '@/modules/todo'
import LeadInteractivity from '@/modules/statistics/LeadInteractivity'
import LEADS from '@/modules/map/LeadsWithLocation.gql'
import HOPPERMATION_DEFAULT_SENDER from '@/modules/emailVerification/queries/HoppermationDefaultSender.gql'
import HOPPERMATION_ORGANIZATION from '@/modules/emailVerification/queries/HoppermationOrganization.gql'
import { isExpertUser, isLightUser } from '@/lib/productTypes'
import BaseHeader from '@/components/BaseHeader.vue'
import { Feature } from 'feature-config'
import brandingMixin from '@/mixins/branding'
import featureNames from '@/lib/featureNames'
import ScoreChart from '@/modules/score/ScoreChart.vue'
import ScoreDetails from '@/modules/score/scoreDetails.vue'
import GET_COMPANY_SCORES from '@/modules/score/queries/GetCompanyScores.gql'
import INVITATION_STATISTICS_FOR_COMPANY from '@/modules/acquisitionBooster/InvitationStatisticsForCompany.gql'

export default {
  components: {
    Feature,
    LeadMap,
    Statistics,
    LeadInteractivity,
    BaseHeader,
    HeaderDesign,
    CorporateDashboard,
    EmailVerification: () => import('@/modules/emailVerification'),
    AcquisitionBoosterDashboard: () => import('@/modules/acquisitionBooster/AcquisitionBoosterDashboard'),
    ScoreDetails,
    ScoreChart,
    Todo
  },
  mixins: [brandingMixin],
  inject: ['companyBaseData'],

  apollo: {
    leads: {
      query: LEADS
    },
    defaultSender: {
      query: HOPPERMATION_DEFAULT_SENDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    organization: {
      query: HOPPERMATION_ORGANIZATION,
      pollInterval: 60000,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    getCompanyScores: {
      query: GET_COMPANY_SCORES,
      skip () {
        return !this.isLightUser
      },
      result ({ data }) {
        if (data) {
          this.scores = data.getCompanyScores
        }
      }
    },
    invitationStatisticsForCompany: {
      query: INVITATION_STATISTICS_FOR_COMPANY
    }
  },
  data () {
    return {
      featureNames,
      isDetailsOpen: false,
      scores: this.companyScores
    }
  },
  computed: {
    isExpert () {
      return isExpertUser(this.$auth.user)
    },
    isCorporate () {
      return this.companyBaseData?.organizationType === 'CORPORATE'
    },
    isLightUser () {
      return isLightUser(this.$auth.user)
    },
    hasScores () {
      if (this.scores) {
        const { __typename, ...scores } = this.scores
        return Object.values(scores).some((score) => score !== null)
      }
      return false
    },
    showEmailVerification () {
      const isFeatureActive = this.$features.feature(featureNames.HOPPERMATION).isActive
      if (this.$apollo.loading || !isFeatureActive || this.isLightUser) {
        return false
      }
      return this.organization.status !== 'ACTIVE' || !this.defaultSender.isAcknowledged
    },
    isSenderVerified () {
      return this.defaultSender?.isVerified && !this.defaultSender?.isAcknowledged
    },
    baseHeaderTitle () {
      return this.$auth.user.given_name ? `Willkommen zurück,  ${this.$auth.user.given_name}!` : 'Willkommen zurück!'
    }
  }
}
</script>

<style>
.map-loader .v-skeleton-loader__image {
  height: 400px !important;
  border: none;
}

.toggle-btn {
  position: relative;
  left: 95%;
  top: 32px;
  z-index: 10;
}
@media (max-width: 1025px) {
  .toggle-btn {
    left: 90%;
  }
}

@media (min-width: 1265px) {
  .chart-wrapper {
    max-height: 80px !important;
    position: relative;
    bottom: 20px;
  }
}
</style>
