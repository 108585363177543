<template>
  <v-container class="layout-container">
    <language />
  </v-container>
</template>

<script>

import Language from '@/modules/language/index.vue'

export default {
  components: { Language }

}
</script>
