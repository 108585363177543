import featureNames from '@/lib/featureNames'
import { Feature } from 'feature-config'

export default {
  components: {
    Feature
  },
  data () {
    return {
      featureNames
    }
  },
  methods: {
    isFeatureActive (featureSlug) {
      return this.$features.feature(featureSlug) && this.$features.feature(featureSlug).isActive
    },
    getFeature (featureSlug) {
      return this.$features.feature(featureSlug)
    }
  }
}
