<template>
  <div class="mx-4">
    <v-breadcrumbs :items="breadcrumbItems" />
    <lead-property-tracker />
  </div>
</template>

<script>
import LeadPropertyTracker from '@/modules/leads/LeadPropertyTracker'

export default {
  components: {
    LeadPropertyTracker
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: 'Übersicht aller Kontakte', to: { path: `/leads${this.$route?.hash || ''}` }, disabled: false, exact: true },
        { text: 'Details', to: { path: `/leads/${this.$route.params.id}${this.$route?.hash || ''}` }, disabled: false, exact: true },
        { text: 'Eigentums-Radar' }
      ]
    }
  }
}
</script>
