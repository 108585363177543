<template>
  <div v-if="lead">
    <div class="px-6 mt-4 mb-6">
      <h2 class="title">
        Eigentums-Radar von {{ fullName }}
      </h2>
    </div>

    <v-card
      flat
      class="mt-4 mb-2"
    >
      <div class="pa-4">
        <p>
          {{ fullName }} hat am {{ formatDate(creationDate) }} diesen Eigentums-Radar aktiviert.
          Bisher sieht er nur den Überblick und kann die weiteren Kategorien noch nicht aufrufen. Dadurch wecken wir die Neugier von
          {{ fullName }}.
        </p>
        <p>
          An dieser Stelle bringen wir Sie ins Spiel und empfehlen {{ fullName }} die Kontaktaufnahme mit Ihnen.
        </p>

        <p>
          Mit folgendem Link schalten Sie für {{ fullName }} alle weiteren Kategorien frei.
          Den optimalen Zeitpunkt dafür wählen Sie selbst:
        </p>

        <code>{{ urlToCopy }}</code> <v-btn
          color="success"
          x-small
          @click="copyToClipboard"
        >
          <v-icon
            class="mr-2"
            x-small
          >
            mdi-content-copy
          </v-icon>
          Link kopieren
        </v-btn>
      </div>
    </v-card>
    <v-tabs
      v-model="tab"
      centered
      grow
      center-active
      @change="navigateTo"
    >
      <v-tab
        v-for="item in iframeTabsItems"
        :key="item.key"
      >
        {{ item.label }}
      </v-tab>
    </v-tabs>

    <div
      v-if="lead.purchaseProperty.dossier"
      class="mt-2 iframe-wrapper"
    >
      <iframe
        id="iframe"
        :src="lead.purchaseProperty.dossier.url"
        class="iframe"
      />
    </div>
  </div>
</template>

<script>
import LEAD_PROPERTY_TRACKER from './queries/LeadPropertyTracker.gql'
import { clipboard } from '@/lib/clipboard'
import bus, { eventNames } from '@/lib/eventBus'
import { IFRAME_VIEW_ITEMS } from './enums/IFrameItmes'

export default {
  data: () => {
    return {

      tab: 0,
      lpIframeAccessHashes: {
        fullAccess: 'e53c75422c0a09c60d377839fde1a551', // md5('fullAccess')
        project: '46f86faa6bbf9ac94a7e459509a20ed0' // md5('project')
      }
    }
  },
  apollo: {
    lead: {
      query: LEAD_PROPERTY_TRACKER,
      variables () {
        return {
          id: this.$route.params.id
        }
      }
    }
  },
  computed: {
    iframeTabsItems () {
      if (!this.lead.company.country || !IFRAME_VIEW_ITEMS) {
        return
      }
      return IFRAME_VIEW_ITEMS[this.lead.company.country]
    },
    urlToCopy () {
      let url = ''
      const lp = (this.lead.company.landingpages).find(lp => lp.type === 'PROPERTY_TRACKER')
      if (lp && lp.url) {
        url = `${lp.url}/vielen-dank?a=${this.lpIframeAccessHashes.fullAccess}&accessCode=${this.lead.purchaseProperty.dossier.id}&view=${this.iframeTabsItems[this.tab].value}`
      }
      return url
    },
    creationDate () {
      return this.lead?.purchaseProperty?.dossier?.createdAt || new Date()
    },
    fullName () {
      return `${this.lead.firstname} ${this.lead.lastname}`
    }
  },
  methods: {
    dossierTemplate (value) {
      return `/s/dossier/${this.lead.purchaseProperty?.dossier?.id}/${value}`
    },
    navigateTo () {
      if (!this.lead.purchaseProperty.dossier.id) {
        return false
      }
      const naviTarget = this.dossierTemplate(this.iframeTabsItems[this.tab].value)
      const iframe = document.getElementById('iframe').contentWindow
      if (iframe) {
        iframe.postMessage({ navigate: naviTarget }, '*')
      }
    },
    async copyToClipboard  () {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Link in die Zwischenablage kopiert.' })

      return await clipboard(this.urlToCopy)
    }
  }
}
</script>
<style>
  .iframe {
    border: none;
    width: 100%;
    height: calc(100vh - 96px);
    margin-top: -60px;
  }
  .iframe-wrapper {
    overflow: hidden;
  }
</style>
