import { isExpertUser, isLightUser } from '@/lib/productTypes'
import { getInstance } from '../auth/index'

const expertOnly = async (to, from, next) => {
  const { user } = getInstance()

  if (isExpertUser(user)) {
    return next()
  } else {
    return next({ name: 'dashboard', replace: true })
  }
}

const notTypeLight = async (to, from, next) => {
  const { user } = getInstance()

  if (isLightUser(user)) {
    return next({ name: 'dashboard', replace: true })
  }
  return next()
}

export {
  expertOnly,
  notTypeLight
}
