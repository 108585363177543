const STREET_VIEW_MAX_DISTANCE = 50

const mapDefaultsByCountry = {
  'de-DE': {
    zoom: 6,
    center: { lat: 51.1758057, lng: 10.4541194 }
  },
  'de-CH': {
    zoom: 7,
    center: { lat: 46.8596213, lng: 8.3102039 }
  },
  'de-AT': {
    zoom: 7,
    center: { lat: 47.7018182, lng: 13.3923366 }
  }
}

export default {
  data () {
    return {
      zIndex: {
        radius: 1,
        user: 2,
        inactive: 3,
        active: 4,
        propertyRequest: 5
      },
      circles: [],
      markers: [],
      markerClusterer: null,
      map: null
    }
  },
  methods: {
    loadGoogleMaps () {
      if (!window.google || !window.google.maps) {
        const script = document.createElement('script')
        script.setAttribute(
          'src',
          `https://maps.googleapis.com/maps/api/js?language=de&key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}&libraries=places&callback=googleMapsLoaded`
        )
        script.setAttribute('async', '')
        script.setAttribute('defer', '')
        document.head.appendChild(script)
      }
    },
    googleMapsLoaded () {
      return new Promise(resolve => {
        if (window.google) {
          resolve()
        } else {
          window.googleMapsLoaded = resolve
        }
      })
    },
    createButtonForMap (text, className, onClick) {
      const controlDiv = document.createElement('div')
      controlDiv.className = className
      controlDiv.innerHTML = text
      controlDiv.addEventListener('click', onClick)
      return controlDiv
    },
    createStreetViewPanorama ({ lat, lng, map, streetViewPanoramaData }) {
      const google = window.google
      var panorama = new google.maps.StreetViewPanorama(map)
      var oldPoint = new google.maps.LatLng(lat, lng)
      const point = streetViewPanoramaData.location.latLng

      var heading = google.maps.geometry.spherical.computeHeading(point, oldPoint)

      panorama.setPosition(point)
      panorama.setPov({
        heading: heading,
        zoom: 1,
        pitch: 0
      })
      panorama.setVisible(true)
      panorama.controls[google.maps.ControlPosition.TOP_LEFT].push(
        this.createButtonForMap(
          'zurück zu Maps',
          'street-view-button',
          () => panorama.setVisible(false))
      )
    },
    getStreetViewData ({ lat, lng }) {
      var point = new window.google.maps.LatLng(lat, lng)
      var streetViewService = new window.google.maps.StreetViewService()

      return new Promise(resolve => {
        streetViewService.getPanoramaByLocation(point, STREET_VIEW_MAX_DISTANCE, function (
          streetViewPanoramaData,
          status
        ) {
          resolve({
            available: status === window.google.maps.StreetViewStatus.OK,
            status,
            streetViewPanoramaData
          })
        })
      })
    },
    getDefaultCenter (country) {
      const { center } = mapDefaultsByCountry[country]
      return center
    },
    getDefaultZoom (country) {
      const { zoom } = mapDefaultsByCountry[country]
      return zoom
    },
    createMarker ({ type, address, radius, lead }) {
      // eslint-disable-next-line no-undef
      const marker = new google.maps.Marker({
        position: { lat: address.lat, lng: address.lng },
        map: this.map,
        type,
        ...(lead && {
          lead: {
            id: lead.id,
            name: lead.firstname && lead.lastname ? lead.firstname + ' ' + lead.lastname : '',
            email: lead.email
          }
        }),
        radius,
        icon: {
          url: `/img/marker/${type}.svg`,
          scaledSize: { height: 50, width: 50 }
        },
        zIndex: this.zIndex[type],
        // eslint-disable-next-line no-undef
        animation: google.maps.Animation.DROP
      })
      return marker
    },
    createRadius (marker) {
      // eslint-disable-next-line no-undef
      const circle = new google.maps.Circle({
        radius: marker.radius * 1000,
        fillColor: 'orange',
        fillOpacity: 0.2,
        strokeWeight: 1,
        strokeColor: 'white',
        zIndex: this.zIndex.radius
      })
      circle.bindTo('center', marker, 'position')
      circle.bindTo('map', marker, 'map')
      marker._myCircle = circle
      return circle
    },
    applyFilterToMap (visibileItemSlugs) {
      if (this.markerClusterer) {
        this.markerClusterer.clearMarkers()
        this.markerClusterer.addMarkers(this.markers.filter(marker => visibileItemSlugs.includes(marker.type)))
      } else {
        this.markers.forEach(marker => {
          if (visibileItemSlugs.includes(marker.type)) {
            marker.setMap(this.map)
            if (marker._myCircle) marker._myCircle.setMap(this.map)
          } else {
            marker.setMap(null)
            if (marker._myCircle) marker._myCircle.setMap(null)
          }
        })
      }
    }
  }
}
