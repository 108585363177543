const URL = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
const EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i

export const empty = (value) => value === null || value === undefined || value === ''

export const betweenRule = (value, min, max) => (value >= min && value <= max) || `Zwischen ${min} - ${max}`
export const length = ({ min = 0, max = '' }) => (value) => empty(value) || new RegExp(`^.{${min},${max}}$`).test(value) ||
 `Die Länge muss zwischen ${min} und ${max === '' ? 'unbegrenzt' : max} Zeichen sein`
export const zip = (value) => empty(value) || /^\d+$/.test(value) || 'Nur Zahlen eintragen'
export const phoneStripped = (value) => empty(value) || !/^(\+|00)/.test(value) || 'Nummer darf keine Ländervorwahl enthalten'
export const email = (value) => empty(value) || EMAIL.test(value) || 'Bitte gültige E-Mail eintragen'
export const url = (value) => empty(value) || URL.test(value) || 'Bitte gültige URL eintragen (inkl. https://)'
export const required = (value) => !!value || value === 0 || 'Pflichtfeld'
export const halfNumber = (value) => value ? (value % 0.5 === 0 || 'Nur Zahlen in 0.5 Schritten') : true
export const integer = (value) => value ? (value % 1 === 0 || 'Nur ganze Zahlen') : true
export const optionalForOnboarding = (isOnboarding) => (value) => !!value || isOnboarding || 'Pflichtfeld'
export const optionalBooleanForOnboarding = (isOnboarding) => (value) => value !== undefined || isOnboarding || 'Pflichtfeld'
export const positiveNumber = (value) => value ? (value > 0 || 'Nur positive Zahlen') : true

/**
 * @deprecated
 */
export const fullNumber = integer
