<template>
  <div>
    <BaseHeader
      title="Meine persönlichen Daten"
      :style-variant="3"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>Passen Sie Ihre persönliche Daten an um die App optimal nutzen zu können.</p>
      </template>
    </BaseHeader>
    <v-alert
      v-if="showAlert"
      icon="mdi-lock"
      type="warning"
      text
    >
      Bitte bestätigen Sie Ihre neue E-Mailadresse ({{ emailChangeRequest.newEmail }}) mit dem gesendeten Link. Erst wenn diese bestätigt wurde, ist Ihre neue E-Mail aktiv bei uns hinterlegt.
    </v-alert>
    <v-card outlined>
      <v-card-text>
        <v-form
          v-model="valid"
          @submit.prevent="submit"
        >
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col
                cols="12"
                sm="6"
                lg="4"
              >
                <v-text-field
                  v-model="user.firstname"
                  label="Vorname"
                  outlined
                  required
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                lg="4"
              >
                <v-text-field
                  v-model="user.lastname"
                  label="Nachname"
                  outlined
                  required
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                lg="4"
              >
                <v-text-field
                  v-model="user.email"
                  label="E-Mailadresse"
                  outlined
                  :rules="[rules.email]"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
          <v-col class="text-right ma-0 pa-0">
            <v-btn
              type="submit"
              :disabled="!isDirty()"
              color="primary"
              right
            >
              Speichern
            </v-btn>
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { email } from '@/lib/validation'
import CREATE_EMAIL_CHANGE_REQUEST from './queries/CreateEmailChangeRequest.gql'
import UPDATE_USER from './queries/UpdateUser.gql'
import EMAIL_CHANGE_REQUEST from './queries/EmailChangeRequest.gql'
import IDENTITY from '../../queries/Identity.gql'
import bus, { eventNames } from '@/lib/eventBus'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from './HeaderDesign.vue'

export default {
  components: { BaseHeader, HeaderDesign },
  data () {
    return {
      originalUser: null,
      user: {
        firstname: this.$auth.user.given_name,
        lastname: this.$auth.user.family_name,
        email: this.$auth.user.email
      },
      rules: {
        email
      },
      error: '',
      emailChangeRequest: {
        hasSendRequest: false,
        newEmail: ''
      },
      valid: false
    }
  },

  computed: {
    showAlert () {
      return this.emailChangeRequest.hasSendRequest
    }
  },

  async mounted () {
    await this.fetchOriginalUser()
    await this.checkEmailChangeRequestExists()
  },

  methods: {
    isDirty () {
      return (this.user.firstname !== this.originalUser?.firstname || this.user.lastname !== this.originalUser?.lastname || this.user.email !== this.originalUser?.email)
    },

    async fetchOriginalUser () {
      try {
        const { data: identity } = await this.$apollo.query({
          query: IDENTITY,
          skip () {
            return this.$auth.loading || !this.$auth.isAuthenticated
          }
        })
        this.originalUser = identity.identity.user
      } catch (e) {
        this.error = e
      }
    },

    async checkEmailChangeRequestExists () {
      try {
        const { data: emailChangeRequest } = await this.$apollo.query({
          query: EMAIL_CHANGE_REQUEST,
          variables: {
            id: this.originalUser.id
          }
        })
        this.emailChangeRequest.newEmail = emailChangeRequest.newEmail
        this.emailChangeRequest.hasSendRequest = !!this.emailChangeRequest.newEmail
      } catch (e) {
        this.error = e
      }
    },

    async submit () {
      if (!this.valid) {
        return
      }

      const hasNewFirstName = this.user.firstname !== this.originalUser.firstname
      const hasNewLastName = this.user.lastname !== this.originalUser.lastname
      // if the name changed, update it right away
      if (hasNewFirstName || hasNewLastName) {
        const firstname = hasNewFirstName ? this.user.firstname : this.originalUser.firstname
        const lastname = hasNewLastName ? this.user.lastname : this.originalUser.lastname
        const { id } = this.originalUser
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_USER,
            variables: {
              input: {
                id,
                firstname,
                lastname
              }
            }
          })
          this.$auth.user.given_name = firstname
          this.$auth.user.family_name = lastname
          this.originalUser.firstname = firstname
          this.originalUser.lastname = lastname
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Daten aktualisiert' })
        } catch (e) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Probleme beim Aktualisieren' })
          this.error = e
        }
      }

      // if the email changed, wait for authentication
      if (this.user.email !== this.$auth.user.email) {
        try {
          const { data: emailChangeRequest } = await this.$apollo.mutate({
            mutation: CREATE_EMAIL_CHANGE_REQUEST,
            variables: {
              input: { id: this.originalUser.id, newEmail: this.user.email }
            }
          })
          this.emailChangeRequest.newEmail = emailChangeRequest.newEmail
          this.emailChangeRequest.hasSendRequest = !!this.emailChangeRequest.newEmail
          this.user.email = this.originalUser.email
        } catch (e) {
          this.error = e
        }
      }
    }
  }
}
</script>
