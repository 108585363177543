<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 127.7 127.7"
    enable-background="new 0 0 127.7 127.7"
    xml:space="preserve"
  >
    <gradient-helper />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="5"
      stroke-miterlimit="10"
      d="M27.6,114.2h56.7c7.8,0,14.2-6.3,14.2-14.2V43.3c0-7.8-6.3-14.2-14.2-14.2H27.6c-7.8,0-14.2,6.3-14.2,14.2V100C13.5,107.9,19.8,114.2,27.6,114.2z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="4"
      stroke-miterlimit="10"
      d="M37.8,104h56.7c7.8,0,14.2-6.3,14.2-14.2V33.1c0-7.8-6.3-14.2-14.2-14.2H37.8c-7.8,0-14.2,6.3-14.2,14.2v56.7C23.7,97.7,30,104,37.8,104z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M47.5,94.4h56.7c7.8,0,14.2-6.3,14.2-14.2V23.5c0-7.8-6.3-14.2-14.2-14.2H47.5c-7.8,0-14.2,6.3-14.2,14.2v56.7C33.3,88,39.6,94.4,47.5,94.4z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="0.5"
      stroke-miterlimit="10"
      d="M56.5,85.3h56.7c7.8,0,14.2-6.3,14.2-14.2V14.4c0-7.8-6.3-14.2-14.2-14.2H56.5c-7.8,0-14.2,6.3-14.2,14.2v56.7C42.4,78.9,48.7,85.3,56.5,85.3z"
    />

    <path
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="6.5"
      stroke-miterlimit="10"
      d="M17.4,124.4h56.7c7.8,0,14.2-6.3,14.2-14.2V53.5c0-7.8-6.3-14.2-14.2-14.2H17.4c-7.8,0-14.2,6.3-14.2,14.2v56.7C3.3,118.1,9.6,124.4,17.4,124.4z"
    />
  </svg>
</template>

<script>
import svg from '@/mixins/svg'
import GradientHelper from './GradientHelper.vue'
export default {
  components: { GradientHelper },
  mixins: [svg]
}
</script>
