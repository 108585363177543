<template>
  <v-row>
    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="1"
      class="d-flex justify-center align-center"
    >
      <v-icon
        color="white"
        class="rounded-circle pa-1"
        :class="{'primary':isDone, 'warning': !isDone}"
        small
      >
        {{ checkIcon }}
      </v-icon>
    </v-col>
    <v-col
      cols="11"
      lg="7"
    >
      <span class="text-subtitle-1">
        <b>{{ title }}</b>
        <v-chip
          v-if="isStarted"
          class="ml-2"
          outlined
          small
          color="warning"
          @click="triggerAppcuesFlow"
        >
          {{ getButtonText }}
        </v-chip>
      </span>
      <p class="ma-0 pa-0">
        {{ text }}
      </p>
    </v-col>
    <v-col
      cols="12"
      lg="4"
      class="d-flex justify-center align-center"
    >
      <v-btn
        v-if="isOpen || isDone"
        small
        :data-appcues="'trigger-' + slug"
        elevation="0"
        class="ma-0"
        @click="startTodo"
      >
        {{ getButtonText }}
      </v-btn>

      <v-btn
        v-if="!isDone && !isOpen && endConditionManual"
        small
        elevation="0"
        class="ma-0"
        :disabled="isDone"
        @click="completeTodo(null)"
      >
        Aufgabe erledigt
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import todoMixin from '@/mixins/todo'

export default {
  mixins: [todoMixin]
}
</script>
