<template>
  <div>
    <v-card
      v-if="mainCompaniesForCorporate?.length <= 0"
      outlined
    >
      <NoContentScreen
        title="Noch keine Büros vorhanden"
        sub-title="Jetzt loslegen und über die Navigationsleiste ein erstes Büro hinzufügen."
      />
    </v-card>
    <v-card
      v-for="company of mainCompaniesForCorporate"
      :key="company.id"
      outlined
      class="mb-4"
    >
      <v-app-bar
        flat
        color="rgba(0, 0, 0, 0)"
      >
        <v-toolbar-title class="text-h6 pl-0">
          {{ companyName(company) }}
        </v-toolbar-title>

        <v-spacer />

        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              class="menu-item"
              @click="showDemoSnackbar"
            >
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Verwalten</v-list-item-title>
            </v-list-item>
            <v-list-item
              class="menu-item"
              @click="showDemoSnackbar"
            >
              <v-list-item-icon>
                <v-icon>mdi-stop-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Deaktivieren</v-list-item-title>
            </v-list-item>
            <v-list-item
              class="menu-item"
              @click="showDemoSnackbar"
            >
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Löschen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-divider />

      <div class="pa-5">
        <v-row>
          <v-col
            v-if="company.address?.city"
            cols="12"
            lg="2"
            class="d-flex justify-center flex-column align-md-center align-start align-lg-start"
          >
            <span class="text-h5">{{ company.address.city }}</span>
            <span>{{ company.address.zip }}, {{ company.address.country }}</span>
            <span>{{ company.address.street }}</span>
          </v-col>
          <v-col
            v-else
            cols="12"
            lg="2"
            class="d-flex justify-center flex-column align-md-center align-start align-lg-start"
          >
            <span class="text-h5">Keine Adresse hinterlegt</span>
          </v-col>
          <v-divider
            vertical
            class="mx-2 d-none d-lg-block"
          />
          <v-col>
            <div class="ml-md-10 d-flex justify-space-between flex-column">
              <Statistics :company-id="company.id" />
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import MAIN_COMPANIES_FOR_CORPORATE from './queries/MainCompaniesForCorporate.gql'
import bus, { eventNames } from '@/lib/eventBus'
import Statistics from './Statistics.vue'
import NoContentScreen from '@/components/NoContentScreen.vue'

export default {
  components: {
    Statistics,
    NoContentScreen
  },
  data () {
    return {
      showInteractionDetails: false
    }
  },
  computed: {
    organizationCompanies () {
      return [
        {
          id: 123,
          slug: '123',
          name: 'Max Adidas Demo',
          statistics: {
            contacts: 100,
            newContacts: 120,
            profit: 2000000
          },
          address: {
            city: 'Stuttgart',
            zip: '70186',
            street: 'Donzenstraße',
            houseNumber: 12
          }
        },
        {
          id: 234,
          slug: '234',
          name: 'Mario Audi Demo',
          statistics: {
            contacts: 10,
            newContacts: 4,
            profit: 100000
          },
          address: {
            city: 'München',
            zip: '80123',
            street: 'Marienplatz',
            houseNumber: 9
          }
        },
        {
          id: 910,
          slug: '910',
          name: 'Bleier Demo',
          statistics: {
            contacts: 300,
            newContacts: 123,
            profit: 15000000
          },
          address: {
            city: 'Waldhausen',
            zip: '20103',
            street: 'Alte Gasse',
            houseNumber: 90
          }
        }
      ]
    }
  },
  apollo: {
    mainCompaniesForCorporate: {
      query: MAIN_COMPANIES_FOR_CORPORATE,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  },
  methods: {
    showDemoSnackbar () {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Funktion für Demo Zwecke abgeschaltet.' })
    },
    companyName (company) {
      return company.name || company.slug
    },
    companyStatisticsContacts (company) {
      return company.statistics?.contacts?.toString() || '0'
    },
    companyStatisticsNewContacts (company) {
      return company.statistics?.newContacts?.toString() || '0'
    },
    companyStatisticsProfit (company) {
      const min = 100000
      const max = 1500000
      const profit = Math.random() * (max - min) + min
      return this.formatPrice(profit).toString()
    }
  }
}
</script>

<style  scoped>
.v-list-item{
  cursor: pointer !important;
}

.v-list-item:hover{
  background: #00000011;
}
</style>
