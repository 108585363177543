<template>
  <div class="overflow-hidden">
    <div
      class="design-wrapper"
      :style="{'visibility': $vuetify.breakpoint.smAndDown ? 'hidden':'visible'}"
    >
      <SingleCircle
        class="design-elements"
        width="50px"
        style="right: 260px; top:5px; opacity: 0.5"
        stroke
        :stroke-width="3"
      />

      <SingleCircle
        class="design-elements"
        width="80px"
        style="left: 250px; top:90px; opacity: 0.4"
        stroke
        :stroke-width="3"
      />

      <SingleCircle
        class="design-elements"
        width="170px"
        style="left: 0px; top:130px; opacity: 0.5"
        fill
      />

      <Blob
        :opacity="0.4"
        class="design-elements"
        width="250px"
        style="right: 0px; top:40px;"
        fill
        :stroke-width="5"
        :duration="40"
        type="basic"
      />

      <Blob
        :opacity="1"
        class="design-elements"
        width="120px"
        style="left: 80px; top:5px"
        stroke
        :stroke-width="10"
        :duration="30"
        type="basic"
      />
    </div>
    <div
      class="no-content-window d-flex justify-center align-center overflow-hidden"
    >
      <Blob
        width="350px"
        class="blob"
        :opacity="0.8"
        fill
      />

      <Blob
        width="400px"
        class="blob"
        :opacity="0.9"
        :blur="50"
        :duration="25"
        fill
        type="complex"
      />
      <div
        class="d-flex justify-center align-center flex-column text text-center"
        style="width:200px"
      >
        <span class="py-4 text-body-2 font-weight-bold white--text">
          {{ title }}
        </span>
        <span class="py-2 text-body-2 white--text">
          {{ subTitle }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import SingleCircle from '@/components/design-elements/singleCircle.vue'
import Blob from '@/components/design-elements/blob.vue'

export default {
  components: {
    SingleCircle,
    Blob
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  }

}
</script>

<style scoped>
.design-wrapper {
  position: relative;
  height: 0.1px;
}
.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.text{
  z-index: 2;
  position: relative;
}
.no-content-window{
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}
.blob {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
