const status = {
  created: 'Manuell angelegt / importiert',
  new: 'Neuer Kontakt',
  followUp: 'Wiedervorlage',
  unreached: 'Aktuell nicht erreicht',
  unreachedAtAll: 'Überhaupt nicht erreicht',
  noFurtherContact: 'Kein weiterer Kontakt erwünscht',
  reached: 'Erfolgreich kontaktiert',
  appointmentOnSite: 'Termin vor Ort vereinbart',
  orderReceived: 'Auftrag erhalten'
}

export const statusForSelect = Object.entries(status).map(([value, text]) => ({ value, text }))

export default status
