<template>
  <div class="h-full">
    <v-row>
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        class="d-flex justify-center align-center ma-0 pa-0"
      >
        <v-icon
          color="white"
          size="80"
          style="opacity: 0.2"
        >
          mdi-checkbox-marked-circle-plus-outline
        </v-icon>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-column h-full">
        <span class="title white--text">
          {{ title }}
        </span>
        <p class="white--text">
          {{ text }}
        </p>

        <v-spacer />
        <div class="d-flex justify-center">
          <v-btn
            :data-appcues="'trigger-' + slug"
            elevation="0"
            :class="{'w-full':$vuetify.breakpoint.mdAndUp}"
            @click="startTodo"
          >
            {{ getButtonText }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import todoMixin from '@/mixins/todo'

export default {
  mixins: [todoMixin]
}
</script>
