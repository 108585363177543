<template>
  <svg
    class="svg-background"
    preserveAspectRatio="none"
    viewBox="0 0 1 1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="g">
        <stop
          offset="0"
          stop-color="#fff"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="#fff"
          stop-opacity="1"
        />
      </linearGradient>
      <mask id="m">
        <rect
          x="0"
          y="0"
          width="1"
          height="1"
          fill="url(#g)"
        />
      </mask>
      <linearGradient
        id="a"
        gradientTransform="rotate(90)"
      >
        <stop
          offset="0"
          :stop-color="topRight"
        />
        <stop
          offset="1"
          :stop-color="bottomRight"
        />
      </linearGradient>
      <linearGradient
        id="b"
        gradientTransform="rotate(90)"
      >
        <stop
          offset="0"
          :stop-color="bottomLeft"
        />
        <stop
          offset="1"
          :stop-color="topLeft"
        />
      </linearGradient>
    </defs>

    <rect
      x="0"
      y="0"
      width="1"
      height="1"
      fill="url(#a)"
      mask="url(#m)"
    />
    <rect
      x="0"
      y="0"
      width="1"
      height="1"
      fill="url(#b)"
      mask="url(#m)"
      transform="translate(1,1) rotate(180)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    topLeft: {
      type: String,
      default: '#ff0000'
    },
    topRight: {
      type: String,
      default: '#00ff00'
    },
    bottomLeft: {
      type: String,
      default: '#0000ff'
    },
    bottomRight: {
      type: String,
      default: '#f0f0ff'
    }
  }
}
</script>

<style scoped>
.svg-background{
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}
</style>
