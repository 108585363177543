
const featureNames = Object.freeze({
  APP_BRANDING: 'app-branding',
  AVV: 'avv',
  BILLWERK: 'billwerk',
  BOTTITHEK: 'bottithek',
  COMPANY_USER: 'company-user',
  COMPANY: 'company',
  CONSULTING: 'consulting',
  CONTACTS: 'contacts',
  CONTRACT: 'contract',
  DATA_PRIVACY: 'data-privacy',
  EVENT_CALENDAR: 'event-calendar',
  FEEDBACK_TICKET: 'feedback-ticket',
  HELPCENTER: 'helpcenter',
  HOPPERMATION: 'hoppermation',
  LANDINGPAGE_NAVIGATION: 'landingpage-navigation',
  LEAD_EXPORT: 'lead-export',
  LICENSE_AGREEMENT: 'license-agreement',
  MATERIAL_GUIDE: 'material-guide',
  NOTIFICATIONS: 'notifications',
  ONBOARDING_PREVIEW: 'onboarding-preview',
  PARTNER: 'partner',
  PERFORMANCE_MARKETING: 'performance-marketing',
  PLOT_VALUATION: 'lp-grundstuecksbewertung',
  POSTAL_SHIPPING: 'postal-shipping',
  REGENERATION: 'regeneration',
  ROADMAP_CONTENT: 'roadmap-content',
  ROADMAP_FEATURES: 'roadmap-features',
  STATIC_CAMPAIGN: 'static-campaign',
  SIGNUP: 'signup',
  SUPPORT_CONTACT: 'support-contact',
  VALUATION: 'valuation',
  ACQUISITION_BOOSTER: 'acquisition-booster',
  LEAD_IMPORT: 'lead-import',
  DELETE_CONTACT: 'delete-contact',
  TEXT_AI_TOPICS: 'text-ai-topics',
  ASKAI_ASSISTENT: 'askai-assistant',
  ASKAI_ASSISTENT_PREVIEW: 'askai-assistant-preview',
  ZOHO_CHAT: 'zoho-chat',
  TODO: 'todo'
})

export default featureNames
