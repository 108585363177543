<template>
  <div class="d-flex flex-md-column flex-row office-stats-wrapper flex-1 mb-2 mb-md-0">
    <div class="mr-2 mr-md-0 mb-md-4 d-flex align-center">
      <v-icon class="mr-2 static-gradient-background background-clip-icon">
        {{ icon }}
      </v-icon>
      <span class="text-h6 font-weight-regular text-md-body-1">{{ title }}</span>
      <span class="text-h6 font-weight-regular d-md-none d-inline-block">:</span>
    </div>
    <span class="text-md-h4 text-h6 font-weight-regular">{{ statistic }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    statistic: {
      type: String,
      default: ''
    }

  }

}
</script>

<style>
.office-stats-wrapper{
  min-width: 200px;
}
</style>
