<template>
  <div class="navbar">
    <v-app-bar
      v-if="$auth.isAuthenticated && $vuetify.breakpoint.mdAndDown"
      color="white"
      clipped-left
      app
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <v-img
          alt="Firmen Logo"
          class="shrink"
          :src="require('@/../public/img/logo.png')"
          width="140"
          min-height="16"
          contain
          @click="goHome"
        />

        <v-spacer />

        <v-app-bar-nav-icon
          v-if="$auth.isAuthenticated"
          @click.stop="showDrawer = !showDrawer"
        />
      </template>
    </v-app-bar>

    <v-navigation-drawer
      v-if="$auth.isAuthenticated"
      v-model="showDrawer"
      class="px-2 pb-3"
      clipped
      app
      mini-variant-width="60"
      :width="270"
      :right="$vuetify.breakpoint.mdAndDown"
      :expand-on-hover="isDrawerCollapsible"
      @transitionend="isDrawerOpen = !isDrawerOpen"
    >
      <!-- Company Logo -->
      <template v-slot:prepend>
        <v-list-item
          id="company-logo"
          to="/dashboard"
          exact
          two-line
          link
          class="px-2"
        >
          <v-list-item-avatar tile>
            <v-img
              alt="Firmen Logo"
              :src="require('@/../public/img/logo-icon.png')"
              @click="goHome"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <v-img
                alt="Volles Firmen Logo"
                :src="require('@/../public/img/logo-name.png')"
                @click="goHome"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <!-- Subcompany login -->
      <template v-if="isCorporate && mainCompaniesForCorporate?.length > 0">
        <v-menu
          v-model="isCompanyMenuOpen"
          style="z-index:11"
          offset-y
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              dense
              v-bind="attrs"
              class="rounded-lg"
              :class="{'grey lighten-3': isDrawerOpen}"
              v-on="on"
            >
              <v-list-item-avatar color="primary">
                <v-icon
                  class="primary-gradient-background"
                  color="white"
                >
                  mdi-login-variant
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <div class="d-flex justify-space-between">
                  <div>
                    <span class="text-caption font-weight-bold">Tochterfirma Login</span>
                    <br><span
                      v-if="selectedChildCompany || company"
                      class="text-caption font-weight-bold grey--text d-inline-block text-truncate"
                      style="max-width: 140px"
                    > {{ selectedChildCompany.name || selectedChildCompany.slug || company.name }}</span>
                  </div>
                  <v-icon
                    small
                    class="pl-2"
                  >
                    {{ isCompanyMenuOpen ? "mdi-arrow-up" : "mdi-arrow-down" }}
                  </v-icon>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item-group
              v-model="selectedChildCompany"
            >
              <v-list-item
                v-for="company in mainCompaniesForCorporate"
                :key="company.slug"
                :value="company"
              >
                <v-list-item-content>
                  <v-list-item-title
                    @click="logIntoChildCompany"
                    v-text="company.name || company.slug"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>

      <!-- Primary Navigation -->
      <v-list class="mt-md-8">
        <v-list-item
          v-for="item in navItems"
          :key="item.to"
          :to="item.to"
          class="rounded-lg"
          active-class="rounded-lg grey lighten-3 font-weight-bold"
          color="primary"
        >
          <v-list-item-icon>
            <v-badge
              v-if="item.badge && item.badge.condition({showRegeneration})"
              bordered
              bottom
              :color="item.badge.color"
              dot
              offset-x="10"
              offset-y="10"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-badge>
            <v-icon v-else>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.label }}
              <v-badge
                v-if="item.isBeta"
                content="BETA"
                color="orange"
                class="ml-2"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <h3
        class="overline grey--text text--darken-1 text-truncate display-block secondary-text mt-md-8"
        :style="{'opacity':isDrawerOpen ? '1':'0'}"
      >
        Weitere Links
      </h3>
      <!-- Secondary Navigation -->
      <v-list
        dense
      >
        <v-list-item
          v-for="item in navItemsBottimmo"
          :key="item.to"
          :to="item.to"
          :href="item.href"
          :target="item.target"
          :rel="item.rel"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-badge
            v-if="item.badge && item.badge.condition({ showRegeneration })"
            :color="item.badge.color"
            :icon="item.badge.icon"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item-content>
          </v-badge>
          <v-list-item-content v-else>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-if="item.href">
            <v-icon
              color="grey lighten-1"
              size="14"
            >
              mdi-open-in-new
            </v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <!-- User Area -->
      <template v-slot:append>
        <v-menu
          v-model="isUserMenuOpen"
          style="z-index:11"
          offset-y
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              dense
              v-bind="attrs"
              class="rounded-lg user-item"
              :class="{'grey lighten-3': isDrawerOpen}"
              v-on="on"
            >
              <v-list-item-avatar color="primary">
                <v-icon
                  class="primary-gradient-background"
                  color="white"
                >
                  mdi-account
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <div class="d-flex justify-space-between">
                  <div>
                    <span
                      v-if="$auth.user.given_name && $auth.user.family_name"
                      class="text-caption font-weight-bold"
                    > {{ $auth.user.given_name + ' ' + $auth.user.family_name }}</span>
                    <span
                      v-else
                      class="text-caption font-weight-bold d-inline-block text-truncate"
                      style="max-width: 140px"
                    > {{ $auth.user.email }}</span>
                    <br><span
                      v-if="company"
                      class="text-caption font-weight-bold grey--text d-inline-block text-truncate"
                      style="max-width: 140px"
                    > {{ company.name || company.slug }}</span>
                  </div>
                  <v-icon
                    small
                    class="pl-2"
                  >
                    {{ isUserMenuOpen ? "mdi-arrow-down" : "mdi-arrow-up" }}
                  </v-icon>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item
              to="/language"
              dense
              exact
              link
            >
              <v-list-item-icon><v-icon>mdi-translate</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sprache: DE</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/profile"
              dense
              exact
              link
            >
              <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Mein Profil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              dense
              @click="logout"
            >
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import COMPANY_BASE from '../../queries/CompanyBase.gql'
import PENDING_CHANGES_FOR_REGENERATION from '../../queries/PendingChangesForRegeneration.gql'
import MAIN_COMPANIES_FOR_CORPORATE from '../../modules/corporateCompany/corporateDashboard/queries/MainCompaniesForCorporate.gql'
import featureNames from '@/lib/featureNames'
import bus, { eventNames } from '@/lib/eventBus'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import { isLightUser } from '@/lib/productTypes'

const CORPORATE_NAVIGATION = [
  {
    label: 'Übersicht',
    to: '/dashboard',
    icon: 'mdi-view-dashboard-outline'
  },
  {
    label: 'Büro hinzufügen',
    to: '/createCompany',
    icon: 'mdi-home-plus'
  }
]

const BASIC_NAVIGATION = [
  {
    label: 'Übersicht',
    to: '/dashboard',
    icon: 'mdi-view-dashboard-outline'
  },
  {
    label: 'Kontakte',
    to: '/leads',
    icon: 'mdi-account-multiple-outline'
  },
  {
    label: 'Landingpages & Köder',
    to: '/landingpages',
    icon: 'mdi-button-pointer'
  },
  {
    label: 'KI-Schmiede',
    to: '/ai-forge',
    icon: 'mdi-brain',
    feature: featureNames.TEXT_AI_TOPICS,
    isBeta: true
  },
  {
    label: 'Immobilienbewertung',
    to: '/valuation',
    icon: 'mdi-file-chart-check-outline',
    feature: featureNames.VALUATION,
    checkConfigIsActive: (config) => config.isInAppValuationEnabled
  },
  {
    label: 'Partner',
    to: '/partner',
    icon: 'mdi-handshake-outline',
    feature: featureNames.PARTNER
  },
  {
    label: 'Performance Pakete',
    to: '/performance',
    icon: 'mdi-gauge',
    feature: featureNames.PERFORMANCE_MARKETING
  },
  {
    label: 'Mein Unternehmen',
    to: '/company',
    icon: 'mdi-card-account-details-outline',
    badge: {
      color: 'warning',
      content: '!',
      condition: ({ showRegeneration }) => showRegeneration
    },
    hideForLightProduct: true
  },
  {
    label: 'Einstellungen',
    to: '/settings',
    icon: 'mdi-cog-outline'
  }
]

const ONBOARING_NAVIGATION = [{
  label: 'Onboarding',
  to: '/onboarding',
  icon: 'mdi-view-dashboard-outline'
}]

const CORPORATE_NAV_ITEMS_BOTTIMMO = [
  {
    label: 'Impressum & Rechtliches',
    to: '/legal',
    onboarding: true,
    icon: 'mdi-file-document-outline'
  }
]

const NAV_ITEMS_BOTTIMMO = [
  {
    label: 'Hilfe',
    href: 'https://hilfe.multiscale.show/portal/de/kb',
    target: '_blank',
    icon: 'mdi-lifebuoy',
    onboarding: false
  },
  {
    label: 'Impressum & Rechtliches',
    to: '/legal',
    onboarding: true,
    icon: 'mdi-file-document-outline'
  }
]

export default {
  mixins: [brandingMixin, featureMixin],
  inject: ['companyBaseData'],
  props: {
    isDrawerCollapsible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // Will be initially set to boolean by the drawer component
      // depending on the viewport size
      showDrawer: null,
      isDrawerOpen: !this.isDrawerCollapsible,
      pendingChangesForRegeneration: {},
      featureNames,
      isUserMenuOpen: false,
      isCompanyMenuOpen: false,
      selectedChildCompany: {}
    }
  },
  computed: {
    showRegeneration () {
      return this.isFeatureActive(this.featureNames.REGENERATION) &&
      Object.keys(this.pendingChangesForRegeneration).length > 0
    },
    inOnboarding () {
      return this.company?.state.stringValues.includes('ONBOARDING')
    },
    isCorporate () {
      return this.company?.organizationType === 'CORPORATE'
    },
    isInactive () {
      return this.company?.state.stringValues.includes('CREATED') ||
        this.company?.state.stringValues.includes('INACTIVE')
    },
    navItems () {
      if (!this.company || this.isInactive) {
        return []
      }

      if (this.inOnboarding) {
        return this.getActiveNavItems(ONBOARING_NAVIGATION)
      }

      if (this.isCorporate) {
        return this.getActiveNavItems(CORPORATE_NAVIGATION)
      }

      return this.getActiveNavItems(BASIC_NAVIGATION)
    },
    navItemsBottimmo () {
      if (!this.company) {
        return []
      }

      if (this.isCorporate) {
        return CORPORATE_NAV_ITEMS_BOTTIMMO
      }

      return this.inOnboarding || this.isInactive
        ? this.getActiveNavItems(NAV_ITEMS_BOTTIMMO.filter(({ onboarding }) => onboarding))
        : this.getActiveNavItems(NAV_ITEMS_BOTTIMMO)
    }
  },
  apollo: {
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated
      }
    },
    pendingChangesForRegeneration: {
      query: PENDING_CHANGES_FOR_REGENERATION,
      variables () {
        return { companyId: this.company.id }
      },
      skip () {
        return !this.company
      }
    },
    mainCompaniesForCorporate: {
      query: MAIN_COMPANIES_FOR_CORPORATE,
      variables () {
        return {
          companyId: this.company.id
        }
      },
      skip () {
        return !this.company || !this.isCorporate
      }
    }
  },
  methods: {
    getActiveNavItems (items) {
      return items.filter(({ feature: featureSlug, checkConfigIsActive, hideForLightProduct }) => {
        if (hideForLightProduct && isLightUser(this.$auth.user)) return false
        if (!featureSlug) return true
        const feature = this.$features.feature(featureSlug)
        return feature?.isActive && (!checkConfigIsActive || checkConfigIsActive(feature?.config))
      })
    },
    logout () {
      this.$auth.logout()
    },
    goHome () {
      this.$router.push({
        name: this.$auth.isAuthenticated ? 'dashboard' : 'home'
      }).catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    },
    logIntoChildCompany () {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Funktion für Demo Zwecke abgeschaltet.' })
    }
  }
}
</script>

<style scoped>
.v-navigation-drawer--mini-variant .v-list-item > *:first-child{
  margin-right:0px !important;
}

.clickable {
  cursor: pointer;
}

.group-header{
  width: 100%;
}

.group-header :deep(.v-list-group__header) {
  padding: 0;
}

.group-item {
  min-height: 12px;
}

.navbar .v-list-item--active {
  border-radius: 10px;
}

.navbar .v-list-item--active:before {
 opacity: 0;
}

.navbar .v-list-item:before {
 border-radius: 10px;
}

.navbar{
  z-index: 10;
}

.user-item{
  transition: background-color .4s;
}

.secondary-text{
  transition: opacity .4s;
}
</style>
