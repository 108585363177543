<template>
  <v-slide-y-transition>
    <v-card
      v-if="isDetailsOpen"
      flat
    >
      <v-card-text>
        <v-divider />
        <v-row>
          <v-col
            cols="12"
            lg="9"
          >
            <p class="mt-4">
              Ihre Performance im Vergleich zur durchschnittlichen Performance aller BOTTIMMO-Kunden in den letzten Monaten:
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-row>
        <v-col
          cols="9"
          md="6"
          lg="3"
        >
          <ScoreChart
            :score="scores.emailScore"
            :label="'bisher ' + scores.emailAmount + ' gesammelte E-Mail-Adressen'"
          />
        </v-col>
        <v-col
          cols="9"
          md="6"
          lg="3"
        >
          <ScoreChart
            :score="scores.phoneScore"
            :label="'bisher ' + scores.phoneAmount + ' Tel. Beratungsanfragen'"
          />
        </v-col>
        <v-col
          cols="9"
          md="6"
          lg="3"
        >
          <ScoreChart
            :score="scores.valuationScore"
            :label="'bisher ' + scores.valuationAmount + ' Immobilienbewertungen'"
          />
        </v-col>
        <v-col
          cols="9"
          md="6"
          lg="3"
        >
          <ScoreChart
            :score="scores.propertyRequestScore"
            :label="'bisher ' + scores.propertyRequestAmount + ' Kaufgesuche (Wohntraumfinder)'"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import ScoreChart from '@/modules/score/ScoreChart.vue'

export default {
  components: { ScoreChart },
  props: {
    isDetailsOpen: {
      type: Boolean,
      default: false
    },
    scores: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
