<template>
  <div>
    <Squares
      class="banner-design-elements"
      width="500px"
      style="right: 0px; top:-100px; opacity: 0.1"
    />
    <Leaf
      class="banner-design-elements"
      width="100"
      style="right: 200px; top:-70px; opacity: 0.8"
      stroke
      :stroke-width="1"
    />
    <Leaf
      class="banner-design-elements"
      width="100"
      style="right: 140px; top:-90px; opacity: 0.5"
      fill
    />
  </div>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'
import Squares from '@/components/design-elements/squares.vue'

export default {
  components: {
    Leaf,
    Squares
  }
}
</script>
