<template>
  <v-dialog
    v-model="showDialog"
    width="600"
    persistent
  >
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>Cookie Hinweis und Einstellungen</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </v-card-title>

      <v-card-text class="pt-0 pb-2 pl-3 pr-3 mt-4 pt-sm-0 pr-sm-6 pb-sm-5 pl-sm-6">
        <p>
          Wir verwenden Cookies, um Ihnen einen optimalen Service zu bieten und
          auf Basis von Analysen unsere Webseiten weiter zu verbessern.
          Sie können selbst entscheiden, welche Cookies wir verwenden dürfen.
        </p>
        <p>
          Bitte beachten Sie, dass technisch notwendige Cookies gesetzt werden
          müssen, um den Betrieb der {{ readablePlatformName }} App sicherstellen zu können.
        </p>

        <p class="mb-0 mb-sm-2">
          <a
            :href="publicDataPrivacyUrl"
            target="_blank"
            rel="noopener noreferrer"
          >Datenschutz</a>
          <a
            :href="imprintUrl"
            target="_blank"
            rel="noopener noreferrer"
            class="ml-4"
          >Impressum</a>
        </p>

        <v-list>
          <v-list-item
            v-for="cookieType in cookieTypes"
            :key="cookieType.value"
            class="ma-0 pa-0"
            @click="toggleChoiceOption(cookieType)"
          >
            <v-list-item-action :class="cookieType.showDescription && 'top-aligned'">
              <v-checkbox
                v-model="choice"
                :value="cookieType.value"
                :readonly="cookieType.readOnly"
                @click="toggleChoiceOption(cookieType)"
              />
            </v-list-item-action>

            <v-list-item-content :class="cookieType.showDescription && 'top-aligned'">
              <v-list-item-title>{{ cookieType.label }}</v-list-item-title>
              <v-expand-transition>
                <p
                  v-if="cookieType.showDescription"
                  class="pt-2 grey--text text--darken-1 description"
                >
                  {{ cookieType.description }}
                </p>
              </v-expand-transition>
            </v-list-item-content>

            <v-list-item-action :class="cookieType.showDescription && 'top-aligned'">
              <v-btn
                icon
                @click.stop="toggleDescription(cookieType.value)"
              >
                <v-icon color="grey lighten-1">
                  mdi-information
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-divider class="d-none d-sm-block" />

      <v-card-actions class="px-4 py-4 flex-column flex-sm-row">
        <v-btn
          color="primary"
          text
          @click="acceptSelected()"
        >
          Auswahl bestätigen
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          @click="acceptAll()"
        >
          Alle Cookies akzeptieren
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import brandingMixin from '@/mixins/branding'

const cookieTypes = [{
  value: 'mandatory',
  label: 'Notwendige Cookies',
  description: `Diese Cookies dienen dem technisch einwandfreien Betrieb
    der ${brandingMixin.computed.readablePlatformName()} App und können nicht deaktiviert werden.`,
  readOnly: true
}, {
  value: 'tracking',
  label: 'Analyse-Cookies',
  description: `Diese Cookies helfen uns die Qualität der ${brandingMixin.computed.readablePlatformName()} App zu
    verbessern. Durch diese Cookies erfahren wir, wie die App benutzt wird
    und können sie so stetig verbessern.`
}]

export default {
  mixins: [brandingMixin],

  data: () => ({
    cookieTypes,
    showDialog: false,
    choice: ['mandatory']
  }),

  mounted () {
    const accepted = this.$cookieConsent.hasAccepted()
    if (!accepted) {
      this.showDialog = true
    }
  },

  methods: {
    toggleChoiceOption (cookieType) {
      if (cookieType.readOnly) {
        return
      }

      const choiceIndex = this.choice.indexOf(cookieType.value)
      if (choiceIndex === -1) {
        this.choice = [...this.choice, cookieType.value]
      } else {
        const newChoice = [...this.choice]
        newChoice.splice(choiceIndex, 1)
        this.choice = newChoice
      }
    },

    toggleDescription (cookieType) {
      this.cookieTypes = this.cookieTypes.map(item => {
        if (item.value === cookieType) {
          item.showDescription = !item.showDescription
        }
        return item
      })
    },

    acceptAll () {
      const choice = Object.values(cookieTypes).map(ct => ct.value)
      this.accept(choice)
    },

    acceptSelected () {
      this.accept(this.choice)
    },

    accept (choice) {
      this.$cookieConsent.accept(choice)
      this.showDialog = false
      if (choice && choice.indexOf('tracking') > -1) {
        this.$gtm.enable(true)
      }
    }
  }
}
</script>

<style scoped>
.top-aligned {
  align-self: flex-start;
}

.description {
  line-height: inherit;
}
</style>
