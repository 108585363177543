<template>
  <AuthView>
    <div class="flex-1">
      <h1 class="display-1 font-weight-black">
        Willkommen
      </h1>
      <p class="mb-10 grey--text text--darken-1">
        in Ihrer {{ readablePlatformName }} App
      </p>
      <login-form />

      <div
        v-if="showSignup"
        class="mt-4 text-center"
      >
        <span>Noch keinen Zugang?</span>
        <a
          href="/auth/signup"
          class="ml-2 primary--text font-weight-bold hover"
        >Registrieren</a>
      </div>

      <v-card
        v-if="showConsulting"
        outlined
        class="mt-8"
      >
        <v-card-text class="d-flex">
          <v-icon
            color="gray"
            large
          >
            mdi-calendar-clock
          </v-icon>
          <div class="pa-4">
            <p class="mb-4">
              Neu hier und neugierig?
              Dann los: Verpassen Sie nicht Ihr bestes Marketing aller Zeiten.
            </p>
            <v-btn
              outlined
              center
              small
              color="primary"
              class="px-4 text-center"
              href="https://calendly.com/bottimmo-marketing-experten/marketing-beratung?utm_source=login-cta&utm_medium=login-app&utm_campaign=sales"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termin vereinbaren und loslegen
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </AuthView>
</template>

<script>
import LoginForm from './LoginForm'
import AuthView from '../AuthView'
import brandingMixin from '@/mixins/branding'
import featureNames from '@/lib/featureNames'
import { consulting, signup } from '@/lib/features'

export default {
  components: { LoginForm, AuthView },
  mixins: [brandingMixin],
  data () {
    return {
      featureNames
    }
  },
  computed: {
    showConsulting () {
      return consulting?.isActive
    },
    showSignup () {
      return signup?.isActive
    }
  }
}
</script>
<style scoped>
.hover:hover{
  cursor: pointer;
}
</style>
