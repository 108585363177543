<template>
  <v-card outlined>
    <v-card-title>
      Passwort zurücksetzen
    </v-card-title>

    <v-card-text>
      Geben Sie bitte Ihre E-Mail-Adresse an.
      Wir werden Ihnen umgehend eine E-Mail senden,
      mit der Sie Ihr Passwort zurücksetzen können.
    </v-card-text>

    <v-expand-transition>
      <v-alert
        v-if="error"
        type="error"
        dense
        tile
        text
      >
        {{ getErrorMessage(error) }}
      </v-alert>
    </v-expand-transition>

    <v-card-text>
      <v-form @submit="submit">
        <v-text-field
          v-model.trim="email"
          label="E-Mail-Adresse"
          type="email"
          outlined
        />

        <v-card-actions class="px-0">
          <router-link :to="{ name: 'login' }">
            Zurück zum Login
          </router-link>
          <v-spacer />
          <loading-button
            color="primary"
            type="submit"
            :loading="submitting"
          >
            absenden
          </loading-button>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import * as Sentry from '@sentry/vue'
import LoadingButton from '@/components/loading-button'

const errorMessages = {
  emptyEmail: 'Bitte geben Sie Ihre E-Mail-Adresse an.',
  unknown: 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut.'
}

export default {
  components: {
    LoadingButton
  },
  data: () => ({
    email: '',
    error: null,
    submitting: false
  }),
  methods: {
    async submit (evt) {
      evt.preventDefault()

      this.error = null

      if (!this.email) {
        this.error = 'emptyEmail'
        return
      }

      this.submitting = true

      try {
        await this.$auth.changePassword({ email: this.email })
        this.$emit('submitted', { email: this.email })
      } catch (err) {
        this.error = 'unknown'
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Change PW failed'
        })
        Sentry.captureException(err)
      }

      this.submitting = false
    },
    getErrorMessage: code => errorMessages[code]
  }
}
</script>
