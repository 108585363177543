<template>
  <v-card
    outlined
    class="statistics-card"
  >
    <div class="pa-5">
      <div
        class="w-full d-flex justify-space-between"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div
              class="title mt-2 grey--text font-weight-light text-truncate d-inline-block"
              v-on="on"
            >
              {{ title }}
            </div>
          </template>
          <span>
            {{ title }}
          </span>
        </v-tooltip>

        <v-icon
          class="static-gradient-background background-clip-icon rounded-circle pa-2 bg-primary"
          :color="iconColor"
          large
        >
          {{ icon }}
        </v-icon>
      </div>
      <div class="pb-0 mt-2 font-weight-medium statistics-number">
        <v-progress-circular
          v-if="!statistic"
          indeterminate
        />
        <div
          v-else
          class="d-inline-block text-truncate"
          style="max-width:100%"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ statistic }}</span>
            </template>
            <span>{{ statistic }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="subTitle"
        class="mt-2 d-flex text-no-wrap"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div
              class="subtitle-1 grey--text text-truncate"
              v-on="on"
            >
              {{ subTitle }}
            </div>
          </template>
          <span>
            {{ subTitle }}
          </span>
        </v-tooltip>
        <div
          class="subtitle-1 grey--text"
        >
          :
          <v-progress-circular
            v-if="!subStatistic"
            indeterminate
            :size="15"
            :width="2"
          />
          <span v-else>{{ subStatistic }}</span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    category: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    statistic: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subStatistic: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.statistics-card:hover{
  cursor: default;
}
.statistics-number{
  font-size: clamp(20px, 20px + 1vw ,2.125rem);
}
</style>
