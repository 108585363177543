<template>
  <v-row class="d-flex align-center">
    <v-col
      cols="6"
      sm="3"
    >
      <StatisticsService
        icon="mdi-account-arrow-left-outline"
        service="Direkt"
        :count="directTrafficCount"
      />
    </v-col>
    <v-col
      cols="6"
      sm="3"
    >
      <StatisticsService
        icon="mdi-qrcode"
        service="QR-Code"
        :count="qrCodeTrafficCount"
      />
    </v-col>
    <v-col
      cols="6"
      sm="3"
    >
      <StatisticsService
        icon="mdi-facebook"
        service="Facebook"
        :count="facebookTrafficCount"
      />
    </v-col>
    <v-col
      cols="6"
      sm="3"
    >
      <StatisticsService
        icon="mdi-google"
        service="Google"
        :count="googleTrafficCount"
      />
    </v-col>
  </v-row>
</template>

<script>
import StatisticsService from './StatisticsService.vue'

export default {
  components: {
    StatisticsService
  },
  props: {
    directTrafficCount: {
      type: Number,
      default: 0
    },
    qrCodeTrafficCount: {
      type: Number,
      default: 0
    },
    facebookTrafficCount: {
      type: Number,
      default: 0
    },
    googleTrafficCount: {
      type: Number,
      default: 0
    }
  }
}
</script>
