<template>
  <v-row
    v-if="hasTodos"
    class="d-flex mt-4"
  >
    <v-col
      v-if="mainTodoItem"
      cols="12"
      md="4"
      lg="3"
    >
      <v-card
        flat
        class="primary-gradient-background white--text"
        :class="{'todo-height': $vuetify.breakpoint.mdAndUp}"
      >
        <v-card-text>
          <MainTodo
            v-if="mainTodoItem"
            v-bind="mainTodoItem"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="8"
      :lg="mainTodoItem ? '9' : '12'"
    >
      <v-card
        outlined
        :class="{'todo-height': $vuetify.breakpoint.mdAndUp}"
      >
        <v-card-title>
          <v-icon
            class="mr-2"
            color="black"
          >
            mdi-list-box-outline
          </v-icon>
          Erfolgs-Tipps
          <v-spacer />
          <v-progress-circular
            :rotate="-90"
            :size="40"
            :width="3"
            :value="completedTodosPercentage"
            color="primary"
          >
            <span
              v-if="completedTodosPercentage < 100"
              class="text-body-2"
            >{{ completedTodosPercentage }}%</span>
            <v-icon
              v-else
              color="primary"
            >
              mdi-check-decagram
            </v-icon>
          </v-progress-circular>
        </v-card-title>
        <v-card-text class="todo-overflow">
          <v-row
            v-for="(singleTodo,i) in otherTodos"
            :key="singleTodo.slug"
          >
            <v-col>
              <TodoItem
                v-bind="singleTodo"
              />
              <v-divider
                v-if="i < otherTodos.length-1"
                class="mt-6"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MainTodo from './MainTodo.vue'
import TodoItem from './TodoItem.vue'
import GET_TODOS from './queries/GetTodos.gql'
import todoStates from './enums/ToDoStates'
import { sortByDate } from '@/lib/sort-functions'
import featureMixin from '@/mixins/feature'

export default {
  components: {
    MainTodo,
    TodoItem
  },
  mixins: [featureMixin],
  data () {
    return {
      todos: []
    }
  },
  apollo: {
    todos: {
      query: GET_TODOS,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId
          }
        }
      }
    }
  },
  computed: {
    hasTodos () {
      return this.todos && this.todos.length > 0
    },
    allowedTodos () {
      const enabledTodosByFeature = {
        'import-contact': this.isFeatureActive(this.featureNames.LEAD_IMPORT),
        'invite-contact': this.isFeatureActive(this.featureNames.ACQUISITION_BOOSTER),
        'property-request': true,
        'use-return-call': true,
        'use-valuation': this.isFeatureActive(this.featureNames.VALUATION),
        calendly: true,
        'facebook-site': true,
        'facebook-post': true,
        'calendly-expert': true,
        partner: this.isFeatureActive(this.featureNames.PARTNER),
        'contact-data': true,
        'product-tour': true
      }
      return this.todos.filter(todo => enabledTodosByFeature[todo.slug])
    },
    sortedTodos () {
      return ([...this.allowedTodos]).sort((a, b) => b.priority - a.priority)
    },
    mainTodoItem () {
      const firstTodo = this.sortedTodos.find(todo => todo.state === todoStates.OPEN)
      return firstTodo
    },
    otherTodos () {
      const startedTodos = ([...this.sortedTodos])
        .filter(todo => todo.state === todoStates.STARTED)

      const others = ([...this.sortedTodos])
        .filter(todo => todo !== this.mainTodoItem && todo.state !== todoStates.STARTED)
        .sort(sortByDate('updatedAt'))

      return [...startedTodos, ...others]
    },
    completedTodosPercentage () {
      if (!this.todos) return 0
      const completedTodos = this.allowedTodos.filter(todo => todo.state === todoStates.FINISHED)
      const percentage = Math.round((completedTodos.length / this.allowedTodos.length) * 100)
      return percentage
    }
  }

}
</script>

<style scoped>
.todo-height {
  height: 400px
}
.todo-overflow {
  max-height: 320px;
  overflow: scroll
}
</style>
