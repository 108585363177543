<template>
  <v-container v-if="error">
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="auto"
        class="mt-8 content"
      >
        <v-card class="root">
          <v-card-title>
            Es ist ein Fehler aufgetreten
          </v-card-title>

          <v-card-text v-if="errorDescription">
            <p>{{ errorDescription }}</p>
            <router-link :to="{ name: 'login' }">
              Zum Login
            </router-link>
          </v-card-text>

          <v-card-text v-else>
            <p>Bitte melden Sie sich erneut an.</p>

            <p>
              Tritt der Fehler weiterhin auf versuchen Sie es bitte mit
              einem anderen Browser oder kontaktieren Sie uns.
            </p>

            <router-link :to="{ name: 'login' }">
              Zum Login
            </router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    error () {
      return this.$route.query.error
    },
    errorDescription () {
      return this.$route.query.error_description
    }
  }
}
</script>

<style scoped>
.content {
  width: 450px;
}
</style>
