import Vue from 'vue'
import VueRouter from 'vue-router'
import multiguard from 'vue-router-multiguard'
import { authenticated, publicOnly } from './guards/auth'
import { feature } from './guards/featureGuard'
import Health from './views/Health.vue'
import Dashboard from './views/Dashboard.vue'
import Profile from './views/Profile.vue'
import Language from './views/Language.vue'
import Verification from './views/Verification.vue'
import LeadPropertyTracker from './views/LeadPropertyTracker.vue'
import Login from './modules/auth/Login/index.vue'
import Logout from './modules/auth/Logout.vue'
import PasswordResetRequest from './modules/auth/PasswordResetRequest/view.vue'
import AuthCallback from './modules/auth/Callback.vue'
import CompanyCreating from './modules/auth/CompanyCreating.vue'
import { state } from './guards/state'
import featureNames from './lib/featureNames'
import { notTypeLight } from './guards/productType'

const LeadDetails = () => import(/*  webpackPrefetch: true, webpackChunkName: "leads" */ './views/LeadDetails.vue')
const Leads = () => import(/*  webpackPrefetch: true, webpackChunkName: "leads" */ './views/Leads.vue')
const Settings = () => import(/*  webpackPrefetch: true, webpackChunkName: "settings" */ './views/Settings.vue')
const Calendar = () => import(/*  webpackPrefetch: true, webpackChunkName: "calendar" */ './views/Calendar.vue')
const AiForge = () => import(/*  webpackPrefetch: true, webpackChunkName: "ai-forge" */ './views/AiForge.vue')
const Performance = () => import(/*  webpackPrefetch: true, webpackChunkName: "performance" */ './views/Performance.vue')
const Company = () => import(/*  webpackPrefetch: true, webpackChunkName: "company" */ './views/Company.vue')
const Valuation = () => import(/*  webpackPrefetch: true, webpackChunkName: "valuation" */ './views/Valuation.vue')
const Landingpage = () => import(/*  webpackPrefetch: true, webpackChunkName: "landingpage" */ './views/Landingpage.vue')
const Landingpages = () => import(/*  webpackPrefetch: true, webpackChunkName: "landingpages" */ './views/Landingpages.vue')
const HelpCenter = () => import(/*  webpackPrefetch: true, webpackChunkName: "help-center" */ './views/HelpCenter.vue')
const Legal = () => import(/*  webpackPrefetch: true, webpackChunkName: "legal" */ './views/Legal.vue')
const Onboarding = () => import(/* webpackChunkName: "onboarding" */ './views/Onboarding.vue')
const OnboardingValidation = () => import(/* webpackChunkName: "onboarding" */ './views/OnboardingValidation.vue')
const Inactive = () => import(/* webpackChunkName: "inactive" */ './views/Inactive.vue')
const LicenseAgreement = () => import(/* webpackChunkName: "licenseAgreement" */ './views/LicenseAgreement.vue')
const Avv = () => import(/* webpackChunkName: "avv" */ './views/Avv.vue')
const Partner = () => import(/* webpackChunkName: "partner" */ './views/Partner.vue')
const Privacy = () => import(/* webpackChunkName: "privacy" */ './views/Privacy.vue')
const CreateCompany = () => import(/* webpackChunkName: "createCompany" */ './views/CreateCompany.vue')
const Registration = () => import(/* webpackChunkName: "registration" */ './views/Registration.vue')

const Signup = () => import(/* webpackChunkName: "signup" */ './modules/auth/Signup.vue')

Vue.use(VueRouter)

const routes = apollo => [
  {
    path: '/_health',
    name: 'health',
    component: Health
  },
  {
    path: '/registration',
    name: 'registration',
    component: Registration,
    beforeEnter: multiguard([authenticated, feature(apollo, featureNames.SIGNUP), state(apollo, ['SELF_ONBOARDING'])])
  },
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'login'
    }
  },
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
    beforeEnter: publicOnly
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/auth/signup',
    name: 'signup',
    component: Signup,
    beforeEnter: multiguard([publicOnly, feature(apollo, featureNames.SIGNUP)])
  },
  {
    path: '/auth/callback',
    name: 'auth-callback',
    component: AuthCallback
  },
  {
    path: '/company-creation',
    name: 'company-creation',
    component: CompanyCreating
  },
  {
    path: '/auth/password-reset',
    name: 'password-reset-request',
    component: PasswordResetRequest,
    beforeEnter: publicOnly
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    redirect: {
      path: '/onboarding/company'
    }
  },
  {
    path: '/onboarding/:step',
    name: 'onboardingStep',
    component: Onboarding,
    beforeEnter: multiguard([authenticated, state(apollo, ['ONBOARDING.USER'])])
  },
  {
    path: '/onboarding-validation',
    name: 'onboardingValidation',
    component: OnboardingValidation,
    beforeEnter: multiguard([authenticated, state(apollo, ['ONBOARDING.VALIDATION'])])
  },
  {
    path: '/inactive',
    name: 'inactive',
    component: Inactive,
    beforeEnter: multiguard([authenticated, state(apollo, ['INACTIVE', 'CREATED'])])
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: multiguard([authenticated, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/createCompany',
    name: 'createCompany',
    component: CreateCompany,
    beforeEnter: multiguard([authenticated, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/leads',
    name: 'leads',
    component: Leads,
    beforeEnter: multiguard([authenticated, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/leads/:id',
    name: 'lead-details',
    component: LeadDetails,
    beforeEnter: multiguard([authenticated, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/leads/:id/eigentums-radar',
    name: 'lead-property-tracker',
    component: LeadPropertyTracker,
    beforeEnter: multiguard([authenticated, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/language',
    name: 'language',
    component: Language,
    beforeEnter: authenticated
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    beforeEnter: authenticated
  },
  {
    path: '/company',
    name: 'company',
    component: Company,
    beforeEnter: multiguard([authenticated, notTypeLight, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/landingpages',
    name: 'landingpages',
    component: Landingpages,
    beforeEnter: multiguard([authenticated, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/landingpages/:id',
    name: 'landingpage-details',
    component: Landingpage,
    beforeEnter: multiguard([authenticated, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/downloads',
    name: 'downloads',
    redirect: {
      name: 'landingpages'
    }
  },
  {
    path: '/valuation',
    name: 'valuation',
    component: Valuation,
    beforeEnter: multiguard([
      authenticated,
      feature(apollo, featureNames.VALUATION, (config) => config.isInAppValuationEnabled),
      state(apollo, ['ACTIVE'])
    ])
  },
  {
    path: '/ai-forge',
    name: 'ai-forge',
    component: AiForge,
    beforeEnter: multiguard([
      authenticated,
      // Might be a different, superordinate feature ('ai-forge').
      // For now we only have this one AI feature.
      feature(apollo, featureNames.TEXT_AI_TOPICS),
      state(apollo, ['ACTIVE'])
    ])
  },
  {
    path: '/performance',
    name: 'performance',
    component: Performance,
    beforeEnter: multiguard([authenticated, feature(apollo, featureNames.PERFORMANCE_MARKETING), state(apollo, ['ACTIVE'])])
  },
  {
    path: '/support',
    name: 'support',
    component: HelpCenter,
    beforeEnter: multiguard([authenticated, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/partner',
    name: 'partner',
    component: Partner,
    beforeEnter: multiguard([authenticated, feature(apollo, featureNames.PARTNER), state(apollo, ['ACTIVE'])])
  },
  {
    path: '/settings',
    name: 'settings',
    redirect: {
      path: '/settings/company'
    }
  },
  {
    path: '/settings/:section',
    name: 'Settings',
    component: Settings,
    beforeEnter: multiguard([authenticated, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/verify/:id',
    name: 'verify',
    component: Verification,
    beforeEnter: multiguard([authenticated, state(apollo, ['ACTIVE'])])
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    beforeEnter: multiguard([authenticated, feature(apollo, featureNames.EVENT_CALENDAR)])
  },
  {
    path: '/legal',
    name: 'legal',
    component: Legal,
    beforeEnter: multiguard([authenticated])
  },
  {
    path: '/license-agreement',
    name: 'licenseAgreement',
    component: LicenseAgreement,
    beforeEnter: multiguard([authenticated, feature(apollo, featureNames.LICENSE_AGREEMENT)
    ])
  },
  {
    path: '/avv',
    name: 'avv',
    component: Avv,
    beforeEnter: multiguard([authenticated])
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    beforeEnter: multiguard([
      authenticated,
      feature(apollo, featureNames.APP_BRANDING, (config) => config.showPrivacyArea)
    ])
  },
  {
    path: '*',
    name: '404',
    redirect: {
      name: 'home'
    }
  }
]

const createRouter = apollo =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes(apollo)
  })

export default createRouter
